import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
import reduxStore from './configureStore';
import { ShvasaChatAiPage } from './ShvasaChatAiPage';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})
function App() {
  return (
    <Provider store={reduxStore}>
      <QueryClientProvider client={queryClient}>
        <ShvasaChatAiPage />
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
