import React, { useEffect, useState } from 'react';
import YogiSVGIcon from '../images/shvasa-ai-yogi.png'
import YogiSVGIconNoBG from '../images/shvasa-ai-yogi-nobg.png'
import { ArrowForwardBlueSVGIcon, WaveSVGIcon } from './svg-icons';
import { Box, Fade, FormControl, IconButton, InputLabel, MenuItem, Select, Slide, TextField } from '@mui/material';
// import { ArrowForward } from '@mui/icons-material';
import ShvasaWaveLogo from '../images/shvasa-wave-logo.svg'
import { Controller, useForm } from 'react-hook-form';
import { CssTextField } from './text-field';
import { MedicalIssueType, ShvasaAIMedicalIssue } from './shvasa-ai-medical-issue';
import { AppDispatch } from '../configureStore';
import { updateUserInfo } from './shvasaChatAISlice';
import { NavLink, Redirect, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowForward } from '@mui/icons-material';
import { FemaleSVGIcon, MaleSVGIcon, NonBinarySVGIcon } from './gender-svg-icons';
import { AGE_PROMPT_HEAD, AGE_PROMPT_TEXT, GENDER_PROMPT_HEAD, GENDER_PROMPT_TEXT, NAME_PROMPT_HEAD, NAME_PROMPT_TEXT, YOGA_AI_INTRO } from './constants';


export const ShvasaYogaAIHome = () => {
    const state = useSelector(state => state.chatAI)

    const [slideUnmounted, setSlideUnmounted] = useState(false);

    useEffect(() => {
        setSlideUnmounted(true);
        setTimeout(() => {
            setSlideUnmounted(false)
        }, 200)
    }, [state.name, state.age, state.gender])

    if (state?.name && state?.age && state?.gender) {
        return (
            <MedicalIssueType state={state} />
        )
    }

    return (
        <>
            <Slide in={!slideUnmounted} direction='left' mountOnEnter={!slideUnmounted} unmountOnExit={slideUnmounted}>
                <div>
                    <Slide direction='left' in={!state.initiated} unmountOnExit>
                        <div className='flex justify-center'><img src={YogiSVGIcon} /></div>
                    </Slide>
                    <div className='text-xl font-medium text-center whitespace-pre-line px-5'>
                        {
                            !state.initiated ? (
                                <>Hey {state.name || ""}, I am <span style={{
                                    color:
                                        'rgba(26, 118, 248,1)'
                                }}>Yogi AI</span></>
                            ) : ''
                        }
                        {
                            state.initiated && !state?.name?.length ? NAME_PROMPT_HEAD : ''
                        }

                        {
                            state.initiated && state?.name?.length && !state?.age?.length ? AGE_PROMPT_HEAD : ''
                        }
                        {
                            state.initiated && state?.name?.length && state?.age?.length && !state?.gender?.length ? GENDER_PROMPT_HEAD : ''
                        }
                    </div>
                    <div className='my-3 font-thin text-center px-10 whitespace-pre-line'>
                        {
                            !state.initiated ? YOGA_AI_INTRO : ''
                        }
                        {
                            state.initiated && !state?.name?.length ? NAME_PROMPT_TEXT : ''
                        }
                        {
                            state.initiated && state?.name?.length && !state?.age?.length ? AGE_PROMPT_TEXT : ''
                        }
                        {
                            state.initiated && state?.name?.length && state?.age?.length && !state?.gender?.length ? GENDER_PROMPT_TEXT : ''
                        }
                    </div>
                    <div className='my-4 flex justify-center'>
                        <WaveSVGIcon />
                    </div>
                    {
                        !state.initiated
                            ? (<div className='flex justify-center'>
                                <IconButton onClick={() => {
                                    AppDispatch(updateUserInfo({ initiated: true }))
                                }} style={{ backgroundColor: '#1a76f8' }} color='primary'>
                                    <ArrowForward htmlColor='#FFF' />
                                </IconButton>
                            </div>) : ''
                    }
                    {
                        (state.initiated && (!state.name || !state.age || !state.gender))
                            ? (<div className='w-full'>
                                <ShvasaAIUser state={state} />
                            </div>) : ''
                    }

                    <div className='my-10 flex flex-col justify-center items-center'>
                        <div className='mb-2'><img src={ShvasaWaveLogo} /> </div>
                        <div className='text-sm'>Powered by shvasa</div>
                    </div>
                </div>
            </Slide>
        </>
    );
}

const ShvasaAIUser = ({ state }) => {
    const { control, formState: { errors }, getValues, handleSubmit, reset, trigger } = useForm();


    console.log("ShvasaAIUser===>", errors, state);
    const onNext = async () => {
        const isSuccess = await trigger();
        console.log("ShvasaAIUser isSusccess", { isSuccess, state });
        if (isSuccess) {
            const values = getValues();
            console.log("ShvasaAIUser onNext===>>", isSuccess, getValues(), state?.name, values?.name)
            if (!state.name && values.name) {
                AppDispatch(updateUserInfo({ name: values.name }))
            }
            if (!state.age && values.age) {
                AppDispatch(updateUserInfo({ age: values.age }))
            }

        }
    }

    const setGender = (gender) => {
        AppDispatch(updateUserInfo({ gender }))
    }

    return (
        <>
            <Box className={`relative mx-5 mt-20 `}>
                <Slide direction='left' in={state.initiated} unmountOnExit>
                    <div className='text-right flex justify-end absolute right-5 -top-20 z-5'>
                        <img src={YogiSVGIconNoBG} />
                    </div>
                </Slide>

                {
                    !state.name
                        ? <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field, value }) => (
                                <Slide direction='right' in={!state.name} mountOnEnter unmountOnExit>
                                    <CssTextField
                                        {...field}
                                        type="text"
                                        variant="outlined"
                                        name="name"
                                        value={value}
                                        required
                                        placeholder="Name"
                                        size="medium"
                                        className="w-full"
                                        InputProps={{
                                            style: {
                                                borderRadius: 30
                                            }
                                        }}
                                        style={{ marginBottom: '20px', background: 'white', borderRadius: 30 }}
                                    />
                                </Slide>
                            )}
                        />
                        : ''
                }


                {
                    (state.name && !state.age)
                        ? <Controller
                            name="age"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field, value }) => (
                                <Slide direction='right' in={state.name && !state.age} mountOnEnter unmountOnExit>
                                    <CssTextField
                                        {...field}
                                        type="text"
                                        variant="outlined"
                                        name="age"
                                        value={value}
                                        required
                                        placeholder="Age"
                                        size="medium"
                                        className="w-full"
                                        InputProps={{
                                            style: {
                                                borderRadius: 30
                                            }
                                        }}
                                        style={{ marginBottom: '20px', background: 'white', borderRadius: 30 }}
                                    /></Slide>
                            )}
                        />
                        : ''
                }

                {
                    (state.name && state.age && !state.gender)
                        ? <Slide direction='right' in={state.name && state.age && !state.gender} mountOnEnter unmountOnExit>
                            <div className='flex justify-around relative z-10'>
                                <IconButton onClick={
                                    () => setGender('male')
                                }>
                                    <div className='justify-center flex flex-col items-center'>
                                        <div><MaleSVGIcon /></div>
                                        <div className='text-xl'>Male</div>
                                    </div>
                                </IconButton>
                                <IconButton onClick={
                                    () => setGender('female')
                                } >
                                    <div className='justify-center flex flex-col items-center'>
                                        <div><FemaleSVGIcon /></div>
                                        <div className='text-xl'>Female</div>
                                    </div>
                                </IconButton>
                                <IconButton onClick={
                                    () => setGender('non-binary')
                                }>
                                    <div className='justify-center flex flex-col items-center'>
                                        <div><NonBinarySVGIcon /></div>
                                        <div className='text-xl'>Non
                                            Binary</div>
                                    </div>
                                </IconButton>
                            </div>
                        </Slide > : ''
                }
                {
                    (!state.name || !state.age)
                        ? (<IconButton onClick={onNext} style={{ padding: 16, position: 'absolute', right: 0, backgroundColor: 'rgba(26, 118, 248, 1)' }}>
                            <ArrowForward htmlColor='#FFF' />
                        </IconButton>)
                        : ''
                }
            </Box>
        </>
    )
}