const { default: BaseAPI } = require("./base.api");

export class UserAPI extends BaseAPI {

    async getUserByEmail(email) {
        console.log("getuserByEmail:", email);
        return await this.post('/user/getUserByEmail', {
            email
        })
    }

    async getQuestions() {
        return await this.get('/user/getOnboardingQuestions', {})
    }

    async getUserById(studentId) {
        console.log("getUserById:", studentId);
        return await this.post('/user/getUserById', {
            id: studentId
        })
    }

    async getSubscriptionsForAStudent(params) {
        return await this.post('/product/getSubscriptionsForAStudent', {
            ...params
        })
    }

    async getUserByToken() {
        return await this.post('/user/getUserByToken', {});
    }
    async getUserOnlyByToken(token) {
        return await this.post('/user/getUserByToken', {}, { Authorization: `Token ${token}` });
    }

    async createNewUser(data) {
        return await this.post('/user/newUser', data)
    }

    async loginWithMagicLink(data) {
        return await this.post('/auth/loginWithMagicLink', data)
    }

    async getUserIPDetails() {
        return await this.get(`https://extreme-ip-lookup.com/json/?key=${process.env.EXTREME_IP_API_KEY}`)
    }

    async getMedicalConditionOptions() {
        return await this.post('/user/getMedicalConditionOptions')
    }
    async updateMedicalConditions(params) {
        return await this.post('/user/createUserMedicalHistory', params)
    }

    async updateReferralPointsData(referralPointData) {
        return await this.post('/user/updateReferralPointsData', referralPointData)
    }

    async getAllFeedbackOptions() {
        console.log("=====.....");
        return await this.post('/product/getAllFeedbackOptions', {});
    }
    async sendMagicLink(email) {
        return await this.post('/auth/sendMagicLink', { email })
    }

    async newLead(res) {
        return await this.post('/user/newLead', res)
    }

    async getAllRefferedUsers({ referralCode }) {
        return await this.post('/user/getAllRefferedUsers', { referralCode })
    }
    async addFreeTrial(entityId, entityType, emailId, sendMagicLink, onlyForNewCustomer, isReactivate, referralCode) {
        console.log(referralCode, "referralCode here api call")
        return await this.post('/product/addFreeTrial', {
            entityId, entityType, emailId, sendMagicLink, onlyForNewCustomer, isReactivate, referralCode
        })
    }

    async getMedicalIssues() {
        return await this.post('/chat/getAllIssue')
    }
}


export const UserAPIInstance = new UserAPI()
