const { default: BaseAPI } = require("./base.api");

class _ChatBotAPI extends BaseAPI {
    async chatGPTHelp({ text, name, age, gender, issueName, createPlan, withImage, sendEmail, email, ...rest }) {
        console.log("Text", text);
        return this.post('/chatbot/chatgpthelp', { text, name, age, gender, issueName, createPlan, withImage, sendEmail, email, ...rest })
    }

    async getAsanasForMedicalIssue({ medicalIssue, tagId, gender }) {
        console.log("getAsanasForMedicalIssue", { medicalIssue, tagId, gender });
        return this.post('/medical-help/getAsanas', { medicalIssue, tagId, gender })
    }

    async getYogaGoals() {
        return this.post('/chatbot/yogaGoals', {})
    }
}

export const ChatBotAPI = new _ChatBotAPI();
