import { IconButton, TextField, Box, Card, CardContent, Avatar, CardMedia, CardActions, List, ListItem, Button, Slide, Dialog, Chip, MenuItem, Zoom } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import YogiSVGIcon from '../images/shvasa-ai-yogi.png'
import './style.css';
import { getNameInitials, templatization } from '../utils/strings';
import { useSelector } from 'react-redux';
import { UserAPIInstance } from '../apis/user.api';
import reduxStore, { AppDispatch } from '../configureStore';
import { clearConvo, setIsLoading, setTyping, updateConvo, updateFlow, updateFlowData, updateMedicalIssueInfo, updateUserInfo } from './shvasaChatAISlice';
import { useMutation, useQuery } from 'react-query';
import { medicalIssueWithIcons } from '../utils/iconutils';
import './style.css'
import { ChatBotAPI } from '../apis/chatbot.api';
import { LoadingDots } from './loading-dots';
import SendIcon from '@mui/icons-material/Send';
import { CHATGPT_POSE_PROMPT, CREATE_SCHEDULE_CONFIRMATION_TEXT, CURATED_CONTENT_TEXT, DONT_CREATE_WEEKLY_SCHEDULE_TEXT, SCHEDULE_CLASS_CONFIRMATION_TEXT, SCHEDULE_CREATED_CONFIRMATION_TEXT, FREE_TRIAL_SCHEDULE, DON_WANT_FREE_TRIAL, BOOK_A_CLASS_TEXT, MENTAL_HEALTH_ISSUE_HELP, CHAT_AI_STORAGE_KEY, PHYSICAL_HEALTH_ISSUE_HELP, CONSTANTS } from './constants';
import { TypingDots } from './typing-dots';
import { delayInMs } from '../utils/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { useNavigate } from 'react-router-dom';
import { PlayIcon } from './svgIcons';
import { Close } from '@mui/icons-material';
import { TabPane } from './ui/tab_pane';
import { ShareIcon } from './svgIcons';
import {
    PopoverComponent
} from './ui/popover'
import { ShareWidget } from './ui/share_widget'
import { BookClassIcon } from './svg-icons';
import { HOME_FLOW_STEP } from './home_flow_step';
import { updateIssue } from './flow_data';

const MESSAGE_DELAY_MS = 1500;
export const registerAndBook = async (emailId, redirect = true) => {
    const state = reduxStore.getState();
    const chatAI = state?.chatAI || {}
    let { name, email, gender, age } = chatAI || {};
    if (emailId) {
        email = emailId;
    }
    AppDispatch(setIsLoading(true))
    const response = await UserAPIInstance.createNewUser({ name, email, gender, age })
    await UserAPIInstance.addFreeTrial(process.env.REACT_APP_FREE_TRIAL_PRODUCT, 'CLASSES', email, true, false)
    AppDispatch(setIsLoading(false))
    const { accessToken, user } = response?.body || {};
    AppDispatch(updateUserInfo({ accessToken }))
    if (redirect) {
        window.open(`https://shvasa-staging-web.vercel.app/scheduleClass?token=${accessToken}`, "_blank")
    }
}

export const sendChatAIMessageText = (text) => {
    const chatAIData = localStorage.getItem(CHAT_AI_STORAGE_KEY);
    let _chatAI = {};
    if (chatAIData) {
        try {
            _chatAI = JSON.parse(chatAIData)
        } catch (err) {
            console.log("errpr ", err);
        }
    }

    AppDispatch(updateConvo({
        text: text,
        name: _chatAI.name || ""
    }))
    scrollToBottomConvo();
}

export const scrollToBottomConvo = () => {

    setTimeout(() => {
        const element = document.getElementById('convo-container-ref');
        element.scrollTop = (element.scrollHeight + 100)
    }, 100);
}

export const moveNextStep = async (currentStep) => {
    const state = reduxStore.getState();
    const { currentFlow } = state?.chatAI || {};
    const { data, step, flowName, flowData } = currentFlow || {};
    AppDispatch(setIsLoading(true))
    await delayInMs(() => {
        AppDispatch(setIsLoading(false))
        AppDispatch(updateFlow({
            step: currentStep || step + 1
        }))
        scrollToBottomConvo();
    }, MESSAGE_DELAY_MS);

}

export const setStepData = async (value, stepNo) => {
    const state = reduxStore.getState();
    const { currentFlow } = state?.chatAI || {};
    const { data, step, flowName, flowData } = currentFlow || {};
    const key = `${flowName}-step-${step}`;
    const currentStep = (flowData?.steps || [])[stepNo || step || 0]
    console.log("currentStep =>", currentStep, step);
    AppDispatch(updateFlowData({
        key, value, tagName: currentStep?.tagName
    }))
    if (currentStep?.onSelect) {
        currentStep?.onSelect(currentFlow)
    }
}

export const updateCurrentStepValue = async () => {
    const state = reduxStore.getState();
    const { currentFlow } = state?.chatAI || {};
    const { data, step, flowName } = currentFlow || {};
    const key = `${flowName}-step-${step}`;
    const value = (data || {})[key];
    console.log("updateCurrentStepValue==>", data, value, key);
    if (value?.length) {
        let valueText = value.map((val) => {
            switch (typeof (val)) {
                case 'object':
                    return val?.value?.label;
                case 'string':
                    return val;
            }
        }).join(",")
        sendChatAIMessageText(valueText)
    } else {
        return false;
    }
    return true;
}

export const ShvasaChatAIConvo = () => {
    const convoRef = useRef();
    const textRef = useRef();
    const inputRef = useRef();
    const chatAI = useSelector(state => state.chatAI)
    const [posesResponse, setPosesResponse] = useState(false)
    const currentStepRef = useRef(null);
    const currentFlowRef = useRef(null);
    const navigate = useNavigate();

    const medicalIssueMutation = useMutation(() => {
        return UserAPIInstance.getMedicalIssues();
    });

    useEffect(() => {
        if (!chatAI.issueType && !chatAI.currentFlow?.flowName) {
            setTimeout(() => {
                navigate('/');
            }, 100)
        }
    }, [])

    useEffect(() => {
        if (chatAI.currentFlow) {
            const { step, flowData, values, flowName } = chatAI?.currentFlow || {};
            console.log("currentFlow==>", step, flowData, chatAI?.currentFlow, flowData?.steps[step], currentStepRef.current == step)
            const { selectedIssue } = chatAI || {};

            if (currentStepRef.current == step && currentFlowRef.current === flowName) { }
            else {
                const convoData = flowData?.steps[step];
                const _values = Object.keys(values || {}).map((key) => {
                    const items = values[key];
                    const vals = items.map((val) => {
                        switch (typeof (val)) {
                            case 'object':
                                return val?.value?.label;
                            case 'string':
                                return val;
                        }
                    }).join(",")
                    let data = {};
                    data[key] = vals;
                    return data;
                })
                currentStepRef.current = step;
                currentFlowRef.current = flowName;
                if (convoData) {
                    if (convoData?.condition && !convoData.condition(chatAI)) {
                        console.log("condition failed ", convoData, convoData?.actions?.length);
                        if (convoData?.actions?.length) {
                            const actionData = convoData?.actions[convoData?.actions?.length - 1]
                            if (actionData?.action) {
                                console.log("condition failed  inside actionData =>",)
                                actionData?.action(null, null, chatAI?.currentFlow, chatAI, chatAI?.email)
                            }
                        }
                        return;
                    }
                    let _dataVal = {}
                    _values.forEach((v) => {
                        _dataVal = { ..._dataVal, ...v }
                    })
                    const text = templatization(convoData["text"], { ...chatAI, ..._dataVal, ...(CONSTANTS || {}), yogaHelp: selectedIssue?.yogaHelp })
                    AppDispatch(updateConvo({ ...convoData, text, isYogi: true }))
                }
            }
        }
    }, [chatAI.currentFlow])

    useEffect(() => {
        if (medicalIssueMutation?.isLoading) {
            AppDispatch(setIsLoading(true))
        } else {
            AppDispatch(setIsLoading(false))
        }
    }, [medicalIssueMutation?.isLoading,])


    useEffect(() => {
        if (medicalIssueMutation?.isSuccess && medicalIssueMutation?.data) {
            const text = <><div className='whitespace-pre-line px-5'>Hey {chatAI?.name || ""},</div>
                <div className='whitespace-pre-line'>{
                    (chatAI.issueType || chatAI.type) === 'mental' ? MENTAL_HEALTH_ISSUE_HELP : PHYSICAL_HEALTH_ISSUE_HELP} </div></>;
            let result = {
                isYogi: true,
                text: (
                    <>
                        {text}
                        <List>
                            {
                                medicalIssueWithIcons({ conditionsList: medicalIssueMutation?.data?.body?.filter((c) => (c?.issueType || c?.type) === (chatAI.issueType))?.filter((cond) => cond?.name?.toLowerCase() !== 'none') || [] })?.map((d) => {
                                    return (
                                        <ListItem key={d?._id} button divider onClick={() => updateIssue(d)}>
                                            <div className=' flex gap-2 items-center'>
                                                <div className='svg-wrapper-h50'>
                                                    {d.svgIcon}
                                                </div>
                                                <div>{d?.name}</div>
                                            </div>
                                        </ListItem>
                                    );
                                })}
                        </List></>)
            }
            AppDispatch(updateConvo(result))
        }
    }, [medicalIssueMutation?.isSuccess, medicalIssueMutation?.data])

    const restartChat = () => {
        AppDispatch(clearConvo({}));
        window.location.href = '/'
        //medicalIssueMutation.mutate();
    }

    return (

        <div className='relative w-full smooth-scroll'>
            <Box id="convo-container-ref" className='min-h-screen smooth-scroll overflow-y-scroll pb-32' ref={convoRef} style={{ maxHeight: 'calc(100vh - 50px)' }}>
                {
                    chatAI?.convo?.map((c, index) => {
                        const isPastStep = chatAI?.convo?.length - 1 > index
                        let _props = { ...c };
                        if (c.type === 'action') {
                            const actions = <ActionCard isPast={isPastStep} actions={c.actions} layout={c?.layout || "row"} />
                            _props['actions'] = actions
                        } else if (c.type === 'swiper') {
                            return <SwiperItemList {..._props} />
                        } else if (c.type === 'tab') {
                            return <TabPane {..._props} />
                        }
                        return <ChatBubble {..._props} />
                    })
                }
                {
                    chatAI?.isLoading
                        ? (<ChatBubble {...{ isYogi: true, text: <TypingDots /> }} />) : ''
                }
                {
                    chatAI?.typing
                        ? (<ChatBubble {...{ isYogi: true, text: <TypingDots /> }} />) : ''
                }
            </Box>
            <Box style={{ width: 50, left: "min(90%,460px)", margin: '0 auto' }} className="flex justify-end items-center  fixed sm-container left-0 right-0 bottom-0 w-full p-5 z-10">
                <div>
                    {
                        chatAI?.accessToken
                            ? (<div>
                                <Zoom in={true} unmountOnExit>
                                    <IconButton style={{ backgroundColor: '#1A76F8', borderRadius: '50%' }} onClick={() => {
                                        window.open(`https://app.shvasa.com/scheduleClass?token=${chatAI?.accessToken}`, '_blank')
                                    }} variant='contained' color='primary' disableElevation>
                                        <BookClassIcon strokeColor="#FFF" />
                                    </IconButton>
                                </Zoom>
                            </div>)
                            : <div></div>
                    }
                    <div className='flex gap-2 flex-col'>
                        <PopoverComponent buttonComponent={({ onClick }) => <ShareIcon />}>
                            <MenuItem>
                                <div className='py-5 share-social'>
                                    <ShareWidget url={window.location.href} />
                                </div>
                            </MenuItem>
                        </PopoverComponent>
                        <IconButton onClick={restartChat}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.75 25.4229C5.84644 25.4229 0.25 19.8264 0.25 12.9229C0.25 6.01929 5.84644 0.422852 12.75 0.422852C19.6535 0.422852 25.25 6.01929 25.25 12.9229C25.25 19.8264 19.6535 25.4229 12.75 25.4229ZM18.7751 19.268C20.4535 17.6737 21.5 15.4205 21.5 12.9229C21.5 8.09036 17.5825 4.17285 12.75 4.17285C7.91751 4.17285 4 8.09036 4 12.9229C4 17.7554 7.91751 21.6729 12.75 21.6729C14.0761 21.6729 15.3333 21.3779 16.4595 20.85L15.241 18.6567C14.4779 18.9887 13.6354 19.1729 12.75 19.1729C9.29823 19.1729 6.5 16.3746 6.5 12.9229C6.5 9.47108 9.29823 6.67285 12.75 6.67285C16.2017 6.67285 19 9.47108 19 12.9229H15.25L18.7751 19.268Z" fill="#1A76F8" />
                            </svg>
                        </IconButton>
                    </div>
                </div>

            </Box>
            {/* <Box className='flex items-center absolute bottom-0 w-full p-5 bg-white'>
                <TextField inputRef={textRef} fullWidth variant='outlined' placeholder='Type message' />
                <IconButton onClick={sendMessage}>
                    <SendIcon />
                </IconButton>
            </Box> */}
        </div>

    )
}

const ChatBubble = ({ isYogi, name, userImage, text, imageURL, videoURL, videoThumbnailURL, actions, onLoad, blurBg }) => {
    useEffect(() => {
        const state = reduxStore.getState();
        const chatAI = state?.chatAI || {};
        if (onLoad) onLoad(chatAI);
        return () => {
            console.log("Bubble unmounted....", text);
        }
    }, [])
    return (
        <div className={`p-5 ${blurBg ? 'blur-bg' : ''}`}>
            <div className={`flex gap-2 ${isYogi ? '' : 'flex-row-reverse'}`}>
                {
                    isYogi
                        ? <img style={{ maxHeight: 30, maxWidth: 30 }} src={YogiSVGIcon} />
                        : (
                            userImage
                                ? <Avatar src={userImage} />
                                : <Avatar>{getNameInitials(name || "")?.toUpperCase()}</Avatar>
                        )
                }
                <Card className={`chat-bubble-card ${isYogi ? 'yogi-bubble' : 'user-bubble'} whitespace-pre-line leading-7`} >
                    {
                        imageURL
                            ? <CardMedia
                                image={imageURL}
                            />
                            : ''
                    }
                    {
                        videoURL
                            ? <div>
                                <iframe style={{ width: "100%", height: 240 }} src={videoURL} />
                            </div>

                            : ''
                    }
                    <CardContent>
                        {text}
                    </CardContent>
                    {
                        actions
                            ? (<CardActions>
                                {actions}
                            </CardActions>)
                            : ''
                    }

                </Card>
            </div>
        </div >
    );
}


export const ActionCard = ({ text, actions, layout, isPast }) => {
    const inpRef = useRef();
    const [error, setError] = useState(false)
    const chatAI = useSelector(state => state.chatAI)
    const { currentFlow } = chatAI || {};
    const { step, data, flowName } = currentFlow || {};
    const key = `${flowName}-step-${step}`;

    const stepValues = data ? data[key] : [];
    return (
        <>
            <div>{text}</div>
            <div className={`flex justify-start w-full flex-${layout} gap-1`} style={{ flexWrap: layout === 'wrap' ? 'wrap' : null }}>
                {
                    actions?.map((ac) => {
                        if (ac.type === 'button') {
                            return (<Button
                                disabled={isPast}
                                disableElevation fullWidth={actions?.length === 1 || ac?.fullWidth}
                                onClick={async () => {
                                    console.log("button actions===>", ac);
                                    if (ac.action) {
                                        const state = reduxStore.getState();
                                        const { currentFlow } = state?.chatAI || {};
                                        ac.action(inpRef, ac?.id, currentFlow, chatAI)
                                    } else {
                                        if (ac?.value) { setStepData(ac?.value || ac?.label) }
                                        const done = await updateCurrentStepValue()
                                        console.log("done", done);
                                        if (done) {
                                            setError(false)
                                            if (ac?.noNext) {
                                                return;
                                            }
                                            moveNextStep()
                                        } else {
                                            setError(true)
                                        }
                                    }
                                }}
                                variant={ac?.variant || 'outlined'}
                                className={ac?.className}
                                style={ac?.style}
                                size={ac?.size || 'small'}
                                color='primary'><span className='capitalize'>{ac.label}</span></Button>
                            )
                        } else if (ac.type === 'input') {
                            return (
                                <TextField disabled={isPast} id={ac?.id} size='small' placeholder={ac?.label} inputRef={inpRef} />
                            )
                        } else if (ac.type === 'chip') {
                            let _value = ac?.value;
                            const isSelected = stepValues?.includes(_value || ac?.label || "")

                            return (
                                <Chip disabled={isPast} onClick={ac?.action ? ac?.action : () => setStepData(ac?.value || ac?.label)} color='primary' clickable variant={isSelected ? 'contained' : 'outlined'} id={ac?.id} size='small' label={ac?.label} />
                            )
                        } else if (ac.type === 'link') {
                            return <a target={ac?.target || '_blank'} href={ac?.href}>
                                {ac?.text} {ac?.imgSrc ? <img src={ac?.imgSrc} style={ac?.style} /> : ''}
                            </a>
                        }
                    })
                }
                {
                    error ?
                        (<div style={{ fontSize: 12 }} className='text-xxs rounded-sm text-red-500 my-3 py-1 px-5 w-full border-red-100 bg-red-50 border'>Please select a value</div>)
                        : ''
                }
            </div>
        </>
    )
}

export const SwiperItemList = (props) => {
    const paginationRef = useRef();

    return (
        <div className='relative'>
            <Swiper
                modules={[Pagination, Navigation]}
                spaceBetween={30}
                slidesPerView={1.5}
                className='relative'
                navigation={
                    {
                        enabled: true,
                        prevEl: '.prev-navigation',
                        nextEl: '.next-navigation'
                    }
                }
                pagination={{
                    clickable: true,
                    el: '.swiper-pagination-container',//paginationRef.current,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '"></span>';
                    },
                }}
                centeredSlides={true}
                onSlideChange={() => console.log('slide change')}>
                {
                    props?.items?.map((item) => {
                        return (
                            <SwiperSlide className=''>
                                <SwiperSlideComponent item={item} />
                            </SwiperSlide>
                        );
                    })
                }
            </Swiper>
            <div ref={paginationRef} style={{ position: 'relative' }} className='swiper-pagination-container swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal'></div>

            <div className='flex justify-between absolute w-full top-0 bottom-0'>
                <IconButton style={{ background: '#ffffff69', borderRadius: 0 }} className='prev-navigation z-10'>
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.82848 6.85226L7.7782 1.90249L6.36399 0.488281L-2.19345e-05 6.85226L6.36399 13.2162L7.7782 11.802L2.82848 6.85226Z" fill="black" />
                    </svg>
                </IconButton>
                <IconButton style={{ background: '#ffffff69', borderRadius: 0 }} className='next-navigation z-10'>
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.17152 6.85226L0.221802 1.90249L1.63601 0.488281L8.00002 6.85226L1.63601 13.2162L0.221802 11.802L5.17152 6.85226Z" fill="black" />
                    </svg>
                </IconButton>
            </div>
            {/* <Dialog fullWidth open={videoURL !== null} onClose={closeVideo}>
                <div>
                    <IconButton onClick={closeVideo}>
                        <Close />
                    </IconButton>
                </div>
                <iframe style={{ width: '100%', height: '100%' }} src={videoURL}></iframe>
            </Dialog> */}
        </div>
    )
}

const SwiperSlideComponent = ({ item }) => {
    const [videoURL, setVideoURL] = useState(null);
    const matchedId = getDriveIdFromUrl(item?.imageURL ?? "");
    let _imageURL = item?.image || item?.imageURL || "";

    const videoId = extractVideoID(item?.videoUrl || "");
    const videoId2 = extractVideoID(videoURL || "");
    const ytVideoEnabled = videoId === videoId2


    const playVideo = (url) => {
        const videoId = extractVideoID(url);
        console.log("VideoId", videoId, url);
        if (videoId) {
            setVideoURL(`https://www.youtube.com/embed/${videoId}?&autoplay=1`)
        }
    }
    const closeVideo = () => {
        setVideoURL(null)
    }
    return (
        <>
            <div className='bg-white shadow-sm flex overflow-hidden border rounded-xl relative' style={{ height: 230, background: '#FFFAF5 !important' }}>
                {
                    !videoURL ?
                        <img style={{ width: '100%', height: '100%' }} className='object-cover' src={_imageURL || 'https://icon-library.com/images/placeholder-image-icon/placeholder-image-icon-21.jpg'} />
                        : ''

                }
                {
                    videoURL ? <iframe style={{ width: '100%', height: '100%' }} src={videoURL}></iframe> : ''
                }

                {
                    item?.name && !videoURL
                        ? <div className='image-bottom-overlay flex justify-between items-center'>
                            <div className='font-semibold text-base px-5 text-white capitalize'>
                                {(item?.englishName || item?.sanskritName) ? `${item?.englishName || ''} ${item?.sanskritName || ""}` : item?.name || ""}</div>
                        </div>
                        : ''
                }
                {
                    (item?.videoUrl || item?.videoURL)?.trim()?.length
                        ? (<div className='image-bottom-overlay flex justify-between items-center'>
                            <div className='font-semibold text-base px-5 text-white capitalize'></div><IconButton onClick={() => playVideo((item?.videoUrl || item?.videoURL))}>
                                <WhitePlayIcon />
                            </IconButton></div>)
                        : ''
                }
            </div>
            <div className='my-5' style={{ fontSize: 13, color: "#4D4D4D" }}>{item?.text}</div>
        </>
    )
}

const WhitePlayIcon = () => {
    return (<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3691 22.0801C5.35982 22.0801 0.488281 17.2085 0.488281 11.1992C0.488281 5.18989 5.35982 0.318359 11.3691 0.318359C17.3784 0.318359 22.25 5.18989 22.25 11.1992C22.25 17.2085 17.3784 22.0801 11.3691 22.0801ZM11.3691 19.9039C16.1767 19.9039 20.0738 16.0067 20.0738 11.1992C20.0738 6.39175 16.1767 2.49453 11.3691 2.49453C6.56167 2.49453 2.66445 6.39175 2.66445 11.1992C2.66445 16.0067 6.56167 19.9039 11.3691 19.9039ZM9.86963 7.29799L15.1783 10.8371C15.3783 10.9704 15.4324 11.2407 15.299 11.4407C15.2671 11.4884 15.2261 11.5295 15.1783 11.5613L9.86963 15.1004C9.66964 15.2338 9.39936 15.1798 9.26609 14.9798C9.21843 14.9083 9.19297 14.8243 9.19297 14.7383V7.66011C9.19297 7.41973 9.38783 7.22487 9.62821 7.22487C9.71416 7.22487 9.79817 7.25033 9.86963 7.29799Z" fill="white" />
    </svg>
    )
}

function extractVideoID(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(shorts\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[8].length == 11) {
        return match[8];
    } else {
        return null;
    }
}

function getDriveIdFromUrl(url) { return url.match(/[-\w]{25,}/); }