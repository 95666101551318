/**
 *
 * ShvasaChatAiPage
 *
 */

import React, { memo, useEffect, useRef } from 'react';

import { BrowserRouter, Route, Router, Routes, } from 'react-router-dom';

import { ShvasaYogaAIHome } from './yoga-ai-home';
import './style.css'
import { ShvasaChatAIConvo } from './ShvasaChatAIConvo';
import { CHAT_AI_STORAGE_KEY } from './constants';
import { AppDispatch } from '../configureStore';
import { updateUserInfo } from './shvasaChatAISlice';
import { FlowGenerator } from './flow_generator';
import { NutritionIntro } from './nutritions/nutrition-intro';
import { BodyMeasurement } from './nutritions/measurement';
import { SelectGoal } from './nutritions/select_goals';
import { MedicalOptions } from './nutritions/medical_options';
import { addAnalyticsEvent } from '../posthog';
import { useSelector } from 'react-redux';

export function ShvasaChatAiPage(props) {

  useEffect(() => {
    const cacheData = localStorage.getItem(CHAT_AI_STORAGE_KEY);

    try {
      const data = JSON.parse(cacheData);
      delete data['convo'];
      AppDispatch(updateUserInfo(data))
    } catch (error) {

    }
  }, [])

  const chatAI = useSelector(state => state.chatAI)
  const flowRef = useRef(null);
  const convoRef = useRef(null);

  useEffect(() => {
    const { convo } = chatAI || {};

    const { flowName, step } = chatAI?.currentFlow || {}
    const lastConvo = convo[convo?.length - 1];
    if (flowRef.current !== flowName || (convoRef.current !== lastConvo && !lastConvo?.isYogi)) {
      console.log("POSTHOG LOGGING", { flowName, step, text: lastConvo?.text }, flowName, flowRef !== flowName, convoRef !== lastConvo, !lastConvo?.isYogi)
      addAnalyticsEvent('picked', { flowName, step, text: lastConvo?.text })
      flowRef.current = flowName;
      convoRef.current = lastConvo;
    }
  }, [chatAI?.currentFlow?.flowName, chatAI?.convo])

  return (
    <div>
      <div className="overflow-hidden	bg-yoga-ai sm-container clarity-city-container  flex flex-col items-center min-h-screen justify-center">
        <BrowserRouter>
          <Routes>
            <Route path={"/start"} element={<ShvasaChatAIConvo />} />
            <Route path={"/"} element={<ShvasaYogaAIHome />} />
            <Route path='/generate' element={<FlowGenerator />} />
            <Route path='/nutrition-intro' element={<NutritionIntro />} />
            <Route path='/measurement' element={<BodyMeasurement />} />
            <Route path='/goal' element={<SelectGoal />} />
            <Route path='/medical' element={<MedicalOptions />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}



export default ShvasaChatAiPage;
