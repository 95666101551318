import React from 'react';
import { styled } from '@mui/material/styles';
const { TextField, } = require("@mui/material");


export const CssTextField = styled(TextField)`
        & label.Mui-focused: {
            color: '#F96500',
        }
        & .MuiInput-underline:after: {
            borderBottomColor: '#C4C4C4',
        }
        & .MuiOutlinedInput-root: {
            & fieldset: {
                borderColor: '#C4C4C4',
            }
            &:hover fieldset: {
                borderColor: '#F96500',
            }
            &.Mui-focused fieldset: {
                borderColor: '#C4C4C4',
            }
        }
    `;