import React, { useState } from 'react';
import { WaveIcon } from '../svgIcons';
import { MultiSelectOption } from '../ui';
import { CircularProgress, IconButton } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { UserAPIInstance } from '../../apis/user.api';
import { Fitness1, Fitness2, Fitness3, Fitness4, Fitness5, Fitness6, Fitness7, Fitness8 } from '../svgIcons';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../configureStore';
import { updateUserInfo } from '../shvasaChatAISlice';

const fitnessGoals = [
    { value: 1, text: "start yoga", svgIcon: <Fitness1 /> },
    { value: 2, text: "improve flexibility", svgIcon: <Fitness2 /> },
    { value: 3, text: "Lose Weight", svgIcon: <Fitness3 /> },
    { value: 4, text: "Restore and Relax", svgIcon: <Fitness4 /> },
    { value: 5, text: "Improve Immunity", svgIcon: <Fitness5 /> },
    { value: 6, text: "Increase Strength", svgIcon: <Fitness6 /> },
    { value: 7, text: "Manage Pain", svgIcon: <Fitness7 /> },
    { value: 8, text: "Deepen Asana Practice", svgIcon: <Fitness8 /> }
]
export const SelectGoal = () => {
    const [selected, setSelected] = useState([])
    const navigate = useNavigate()

    const onNext = () => {
        if (selected?.length) {
            AppDispatch(updateUserInfo({
                'goals': selected?.map((opt) => ({ text: opt?.text, value: opt?.value })),
            }))
            navigate('/medical')
        }
    }
    const { isLoading, isSuccess, data, } = useQuery('question', () => {
        return UserAPIInstance.getQuestions()
    })
    const questions = data?.body || [];

    let fitnessQs = questions.filter(qs => qs.question.toLowerCase().indexOf("fitness goals") != -1)[0]
    fitnessQs = (fitnessQs?.options || []).map((item) => {
        item = fitnessGoals.filter((fg) => {
            console.log("FG", fg?.text.toLowerCase() === item.text?.toLowerCase());
            return fg?.text.toLowerCase() === item.text?.toLowerCase()
                ? fg.svgIcon
                : null;

        })[0]
        return item;
    })
    console.log("fitnessQs====>>", fitnessQs,)

    return (
        <>
            <div className='font-medium text-2xl'>Select your goal</div>
            <div className='mt-3'>A Goal Without a Plan is Just a Dream</div>
            <div className='my-5'>
                <WaveIcon />
            </div>
            <div>
                {
                    isLoading
                        ? <CircularProgress />
                        : <MultiSelectOption options={fitnessQs} onChoose={(data) => {
                            console.log("setSelected====>", data);
                            setSelected(data)
                        }} />
                }

            </div>

            <div className='flex justify-center mt-10'>
                <IconButton onClick={onNext} style={{ backgroundColor: 'rgba(26, 118, 248, 1)' }}>
                    <ArrowForward htmlColor='#FFF' />
                </IconButton>
            </div>

        </>
    );
}