import { Box, CircularProgress, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { ArthritisIcon, DiabetesIcon, HyperTensionIcon, KneeIssueIcon, NoneHeartIcon, OtherIcon, PCOSIcon, ShouldNeckPainIcon, ThyroidIcon, BackPainIcon, WaveIcon } from '../svgIcons';
import { useQuery } from 'react-query';
import { UserAPIInstance } from '../../apis/user.api';
import { MultiSelectOption } from '../ui';
import { ArrowForward } from '@mui/icons-material';
import { AppDispatch } from '../../configureStore';
import { updateFlow, updateFlowData, updateMedicalIssueInfo, updateUserInfo } from '../shvasaChatAISlice';
import { FLOW_DATA } from '../flow_data';
import { useNavigate } from 'react-router-dom';

export const MedicalOptions = () => {
    const { isLoading, data, } = useQuery('medications', () => {
        return UserAPIInstance.getMedicalConditionOptions()
    })
    const [selectedOptions, setSelectedOptions] = useState([])
    const iconsList = {
        "arthritis": (<Box className='svg-wrapper-h100'><ArthritisIcon /></Box>),
        "diabetes": (<Box className='svg-wrapper-h100'><DiabetesIcon /></Box>),
        "none": (<Box className='svg-wrapper-h100'><NoneHeartIcon /></Box>),
        "shoulder": (<Box className='svg-wrapper-h100'><ShouldNeckPainIcon /></Box>),
        "knee": (<Box className='svg-wrapper-h100'><KneeIssueIcon /></Box>),
        "pcos": (<Box className='svg-wrapper-h100'><PCOSIcon /></Box>),
        "thyroid": (<Box className='svg-wrapper-h100'><ThyroidIcon /></Box>),
        "hypertension": (<Box className='svg-wrapper-h100'><HyperTensionIcon /></Box>),
        "back": (<Box className='svg-wrapper-h100'><BackPainIcon /></Box>),
        // "other": (<Box className='svg-wrapper-h100'><OtherIcon /></Box>)
    }
    const conditionsList = data?.body || [];
    const _conditionsList = conditionsList.map(cond => {
        const iconText = cond.text.toLowerCase().split(" ")[0];
        cond["svgIcon"] = iconsList[iconText]
        return cond
    })

    const navigate = useNavigate();

    const onNext = () => {
        if (selectedOptions?.length) {
            AppDispatch(
                updateUserInfo({
                    'medicalConditions': selectedOptions?.map((opt) => ({ text: opt?.text, value: opt?.value }))
                })
            )
            const flowName = 'nutrition';
            AppDispatch(updateMedicalIssueInfo({ 'issueType': null }))
            AppDispatch(updateFlow({ flowName: flowName, step: 0, flowData: FLOW_DATA[flowName] }))
            setTimeout(() => {
                navigate('/start');
            }, 100)
        }
    }

    if (isLoading) {
        return (
            <div>
                <CircularProgress />
            </div>

        )
    }

    return (
        <>
            <div className='font-medium text-2xl'>Select your goal</div>
            <div className='mt-3'>A Goal Without a Plan is Just a Dream</div>
            <div className='my-5'>
                <WaveIcon />
            </div>
            <MultiSelectOption
                containerWrapClass={"flex-wrap"}
                options={_conditionsList}
                onChoose={setSelectedOptions}
                // columns={isMobileOnly ? 4 : 2}   // = w-4/12
                itemMaxWidth="auto"
                className={`md:font-medium`}
            //onReset={onReset}
            />
            <div className='flex justify-center mt-3'>
                <IconButton onClick={onNext} style={{ backgroundColor: 'rgba(26, 118, 248, 1)' }}>
                    <ArrowForward htmlColor='#FFF' />
                </IconButton>
            </div>
        </>
    )
}