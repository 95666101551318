/*
 *
 * ShvasaChatAiPage constants
 *
 */
import { Fitness1, Fitness2, Fitness3, Fitness4, Fitness5, Fitness6, Fitness7, Fitness8 } from './svgIcons';

export const DEFAULT_ACTION = 'app/ShvasaChatAiPage/DEFAULT_ACTION';
export const CHAT_AI_STORAGE_KEY = '_chat_ai_user';
export const CREATE_SCHEDULE_CONFIRMATION_TEXT = `I can create a weekly routine for you to follow in order to reduce your {{issueName}}. \
            \
            Would you like me to proceed ? `;
export const UNLOCK_FREE_TRIAL = `Unlock the full 7-day yoga schedule by simply providing your email, and rest assured that your privacy is in safe hands. So safe, in fact, that not even Edward Snowden could crack it, even if he had a 10,000-character password!😄`;
export const CURATED_CONTENT_TEXT = `Sure {{name}}...
I understand your medical concerns. To help you out, I've curated a selection of informative videos and images that might be useful. `;

export const CHATGPT_POSE_PROMPT = `Alan: I am {{name}} {{age}} year old {{gender}} having {{issueName}},
            what yoga poses will be helpful ? can you create a 7 day yoga schedule for the same and explain each pose ? explaing in 500 words. 
        `;

export const SCHEDULE_CLASS_CONFIRMATION_TEXT = `{{name}},
You can attend 3 live Shvasa classes to address your {{issueName}}.
Would you like to attend 3 classes with our 7 - day free trial ? `;

export const SCHEDULE_CREATED_CONFIRMATION_TEXT = `Sure {{name}}...
We have created a 7 - day yoga schedule specifically designed to reduce {{issueName}}. 

Please provide your email so we can share it with you.
And do not worry, Your privacy is so safe, even Edward Snowden couldn't crack it with a 10,000-character password! 😄
`;
export const DONT_CREATE_WEEKLY_SCHEDULE_TEXT = `No problem {{name}}, I completely understand. Sometimes schedules can be overwhelming or just not fitting into our current routines. 

However, we'd like to present you with an exciting alternative: a 7-day FREE yoga trial!
Would you like to experience the bliss of yoga to help alleviate your {{issueName}}?`;

export const FREE_TRIAL_SCHEDULE = `Great! We have created a 7 - day FREE yoga trial specifically designed to reduce {{issueName}}.

ase provide your email so we can give you access.And don't worry, your privacy is so safe that even Edward Snowden couldn't crack it with a 10,000 - character password! 😄`;

export const DON_WANT_FREE_TRIAL = `No problem at all!.
Is there anything else we can assist you with?`;

export const BOOK_A_CLASS_TEXT = `Great!
Click on the below button to schedule a class.`;

export const YOGA_AI_INTRO = `I am your companion on this journey to untangle the knots of your mind and body with a touch of humor and oodles of ancient wisdom. `

export const MENTAL_HEALTH_ISSUE_HELP = `⭐ Choose from the list below and let the journey towards inner harmony begin. 🌈✨`;
export const PHYSICAL_HEALTH_ISSUE_HELP = `⭐ From the options below, select the challenge that calls to your body and step into a world of wellness. 💪 🔥 `;

export const CHOOSE_TYPE_OF_ISSUE_HEAD = `Which path shall we explore?`;
export const CHOOSE_TYPE_OF_ISSUE_TEXT = `Choose your sacred quest, and together we shall conquer the challenges that lie ahead! 🌈✨`;

export const NAME_PROMPT_HEAD = "So tell me, curious one!";
export const NAME_PROMPT_TEXT = `What shall we call you? Your name is the key to personalizing your journey!`;

export const AGE_PROMPT_HEAD = "How many trips around the sun have you made?";
export const AGE_PROMPT_TEXT = `Relax, this isn't an undercover mission to reveal your secret fountain of youth. Understanding your age helps us customize our yoga recommendations.`;

export const GENDER_PROMPT_HEAD = "We need to know your gender.";
export const GENDER_PROMPT_TEXT = `Fear not, no dress code here! It's simply for a sprinkle of yoga magic.`;

export const MESSAGE_DELAY_MS = 1500;

export const CONSTANTS = {
    CREATE_SCHEDULE_CONFIRMATION_TEXT: `I can create a weekly routine for you to follow in order to reduce your {{issueName}}. \
            \
            Would you like me to proceed ? `,
    CURATED_CONTENT_TEXT: `Sure {{name}}...
I understand your medical concerns. To help you out, I've curated a selection of informative videos and images that might be useful. `,

    CHATGPT_POSE_PROMPT: `Alan: I am {{name}} {{age}} year old {{gender}} having {{issueName}},
            what yoga poses will be helpful ? can you create a 7 day yoga schedule for the same and explain each pose ? in 500 words. 
        `,

    SCHEDULE_CLASS_CONFIRMATION_TEXT: `{{name}},
You can attend 3 live Shvasa classes to address your {{issueName}}.
Would you like to attend 3 classes with our 7 - day free trial ? `,

    SCHEDULE_CREATED_CONFIRMATION_TEXT: `Sure {{name}}...
We have created a 7 - day yoga schedule specifically designed to reduce {{issueName}}. 

Please provide your email so we can share it with you.
And do not worry, Your privacy is so safe, even Edward Snowden couldn't crack it with a 10,000-character password! 😄
`,
    DONT_CREATE_WEEKLY_SCHEDULE_TEXT: `No problem {{name}}, I completely understand. Sometimes schedules can be overwhelming or just not fitting into our current routines. 

However, we'd like to present you with an exciting alternative: a 7-day FREE yoga trial!
Would you like to experience the bliss of yoga to help alleviate your {{issueName}}?`,

    FREE_TRIAL_SCHEDULE: `Great! We have created a 7 - day FREE yoga trial specifically designed to reduce {{issueName}}.

ase provide your email so we can give you access.And don't worry, your privacy is so safe that even Edward Snowden couldn't crack it with a 10,000 - character password! 😄`,

    DON_WANT_FREE_TRIAL: `No problem at all!. Is there anything else we can assist you with?`,

    BOOK_A_CLASS_TEXT: `Great!
Click on the below button to schedule a class.`,

    YOGA_AI_INTRO: `I am your companion on this journey to untangle the knots of your mind and body with a touch of humor and oodles of ancient wisdom. `,

    MENTAL_HEALTH_ISSUE_HELP: `⭐ Choose from the list below and let the journey towards inner harmony begin. 🌈✨`,
    PHYSICAL_HEALTH_ISSUE_HELP: `⭐ From the options below, select the challenge that calls to your body and step into a world of wellness. 💪 🔥 `,

    CHOOSE_TYPE_OF_ISSUE_HEAD: `Which path shall we explore?`,
    CHOOSE_TYPE_OF_ISSUE_TEXT: `Choose your sacred quest, and together we shall conquer the challenges that lie ahead! 🌈✨`,

    NAME_PROMPT_HEAD: "So tell me, curious one!",
    NAME_PROMPT_TEXT: `What shall we call you? Your name is the key to personalizing your journey!`,

    AGE_PROMPT_HEAD: "How many trips around the sun have you made?",
    AGE_PROMPT_TEXT: `Relax, this isn't an undercover mission to reveal your secret fountain of youth. Understanding your age helps us customize our yoga recommendations.`,

    GENDER_PROMPT_HEAD: "We need to know your gender.",
    GENDER_PROMPT_TEXT: `Fear not, no dress code here! It's simply for a sprinkle of yoga magic.`,

    MESSAGE_DELAY_MS: 1500,
}


export const fitnessGoals = [
    { value: 1, text: "start yoga", svgIcon: <Fitness1 /> },
    { value: 2, text: "improve flexibility", svgIcon: <Fitness2 /> },
    { value: 3, text: "Lose Weight", svgIcon: <Fitness3 /> },
    { value: 4, text: "Restore and Relax", svgIcon: <Fitness4 /> },
    { value: 5, text: "Improve Immunity", svgIcon: <Fitness5 /> },
    { value: 6, text: "Increase Strength", svgIcon: <Fitness6 /> },
    { value: 7, text: "Manage Pain", svgIcon: <Fitness7 /> },
    { value: 8, text: "Deepen Asana Practice", svgIcon: <Fitness8 /> }
]