import React from 'react';
import { ArrowForwardBlueSVGIcon, AyurvedicDoshaSVGIcon, MentalHealthIssueSVGIcon, NutritionPlanSVGIcon, PhysicalHealthIssueSVGIcon, WaveSVGIcon } from './svg-icons';
import { updateFlow, updateMedicalIssueInfo } from './shvasaChatAISlice';
import { AppDispatch } from '../configureStore';
import { NavLink, Redirect, useNavigate } from 'react-router-dom';
import { CHAT_AI_STORAGE_KEY, CHOOSE_TYPE_OF_ISSUE_HEAD, CHOOSE_TYPE_OF_ISSUE_TEXT, YOGA_AI_INTRO } from './constants';
import { Button, Slide } from '@mui/material';
import { FLOW_DATA } from './flow_data';
import { CalendarIconTeacher, CalendarScheduleIcon } from './svgIcons';

export const ShvasaAIMedicalIssue = ({ state, dispatch }) => {

    return (
        <>
            <div className="bg-yoga-ai sm-container clarity-city-container  flex flex-col items-center min-h-screen justify-center">
                <MedicalIssueType state={state} dispatch={dispatch} />
            </div>
        </>
    )
}

export const MedicalIssueType = ({ state, dispatch }) => {
    const navigate = useNavigate();

    const updateIssueType = (type) => {
        AppDispatch(updateMedicalIssueInfo({ 'issueType': type }))
        localStorage.setItem(CHAT_AI_STORAGE_KEY, JSON.stringify({ ...state, issueType: type, convo: [], currentflow: [] }))
        setTimeout(() => {
            navigate('/start');
        }, 100)
    }
    const selectCurrentFlowData = (flowName, issueType = null) => {
        AppDispatch(updateMedicalIssueInfo({ 'issueType': issueType }))
        console.log("FLOW_DATA[flowName]:", FLOW_DATA[flowName], flowName);
        AppDispatch(updateFlow({ flowName: flowName, step: 0, flowData: FLOW_DATA[flowName] }))
        //state['convo'] && delete state['convo']
        //state['currentFlow'] && delete state['currentFlow']
        localStorage.setItem(CHAT_AI_STORAGE_KEY, JSON.stringify({ ...state, issueType: null, convo: [], currentflow: [] }))
        setTimeout(() => {
            navigate('/start');
        }, 100)
    }
    const bookConsultation = () => {
        window.open('https://app.shvasa.com/book-consultation/', '_target')
    }

    return (
        <Slide in={true} direction='left' mountOnEnter unmountOnExit>
            <div>
                <div className='text-lg text-center whitespace-pre-line'>{state?.name}, {CHOOSE_TYPE_OF_ISSUE_HEAD}</div>
                <div className='my-3 text-sm text-center px-10'>
                    {CHOOSE_TYPE_OF_ISSUE_TEXT}
                </div>
                <div className='my-4 flex justify-center'>
                    <WaveSVGIcon />
                </div>
                <div className='w-full px-10'>
                    <div className='bg-white rounded-2xl px-5 shadow-lg'>
                        <div onClick={() => selectCurrentFlowData('mentalmedicalIssue', 'mental')} className='flex justify-between items-center'>
                            <div className='flex items-center'>
                                <div><MentalHealthIssueSVGIcon /></div>
                                <div>Mental health </div>
                            </div>
                            <div><ArrowForwardBlueSVGIcon /></div>
                        </div>
                    </div>
                    <div className='bg-white rounded-2xl px-5 shadow-lg my-4'>
                        <div onClick={() => selectCurrentFlowData('physicalmedicalIssue', 'physical')} className='flex justify-between items-center'>
                            <div className='flex items-center'>
                                <div><PhysicalHealthIssueSVGIcon /></div>
                                <div className='ml-2'>Physical health </div>
                            </div>
                            <div><ArrowForwardBlueSVGIcon /></div>
                        </div>
                    </div>
                    <div className='bg-white rounded-2xl px-5 shadow-lg my-4'>
                        <div onClick={() => {
                            navigate('/nutrition-intro')
                            //selectCurrentFlowData('nutrition')
                        }
                        } className='flex justify-between items-center'>
                            <div className='flex items-center'>
                                <div>
                                    <NutritionPlanSVGIcon />
                                </div>
                                <div className='ml-2'>Nutrition Plan</div>
                            </div>
                            <div><ArrowForwardBlueSVGIcon /></div>
                        </div>
                    </div>
                    <div className='bg-white rounded-2xl px-5 shadow-lg my-4'>
                        <div onClick={() => selectCurrentFlowData('PRAKRITI_ANALYSIS')} className='flex justify-between items-center'>
                            <div className='flex items-center'><div>
                                <AyurvedicDoshaSVGIcon />
                            </div>
                                <div className='ml-2'>Ayurvedic Dosha Test</div></div>
                            <div><ArrowForwardBlueSVGIcon /></div>
                        </div>
                    </div>
                    <div className='bg-white rounded-2xl pl-2 pr-3 shadow-sm my-4'>
                        <div onClick={() => bookConsultation()} className='flex justify-between items-center'>
                            <div className='flex items-center'><div>
                                <div className='flex justify-center items-center' style={{ width: 70, height: 70 }}><CalendarScheduleIcon /></div>
                            </div>
                                <div className='ml-2'>Book Consultation</div></div>
                            <div><ArrowForwardBlueSVGIcon /></div>
                        </div>
                    </div>
                </div>
            </div>
        </Slide>
    )
}
