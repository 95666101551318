import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React from 'react';

export const ListComponent = ({ items }) => {
    return (
        <List>
            {
                items?.map((item) => {
                    return <ListItemComponent
                        item={item}
                        icon={item?.icon}
                        title={item?.title}
                        subtitle={item?.subtitle}
                    />
                })
            }
        </List>
    )
}
export const ListItemComponent = ({item, icon, title, subtitle, onClick, svgIcon }) => {
    return (
        <ListItem button onClick={() => onClick({item, name: title })}>
            <div className=' flex gap-2 items-center'>
                <div className='svg-wrapper-h50'>
                    {svgIcon || icon}
                </div>
                <div>{title}</div>
            </div>
        </ListItem>
    )
}