import { ArrowForward } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppDispatch } from '../../configureStore';
import { updateFlow, updateMedicalIssueInfo } from '../shvasaChatAISlice';
import { FLOW_DATA } from '../flow_data';
import { useNavigate } from 'react-router-dom';

export const NutritionIntro = () => {
    const navigate = useNavigate();
    const gotoNutritionFlow = () => {
        const flowName = 'nutrition';
        AppDispatch(updateMedicalIssueInfo({ 'issueType': null }))
        AppDispatch(updateFlow({ flowName: flowName, step: 0, flowData: FLOW_DATA[flowName] }))
        setTimeout(() => {
            navigate('/start');
        }, 100)
    }
    return (
        <div className='min-h-screen flex flex-col items-center justify-center px-5'>
            <div className='font-medium' style={{ fontSize: 24 }}>Brave traveler...</div>
            <div className='text-center my-3 leading-6 font-thin' style={{ color: '#4d4d4d' }}>
                Get ready for an adventurous journey to discover your perfect nutrition diet plan! Answer the questions ahead and behold a customized, magical feast tailored just for you. Your body will thank you for the nourishment it's about to receive! Onward, intrepid soul, let the quest begin!
            </div>
            <div>
                <IconButton onClick={gotoNutritionFlow} style={{ color: '#FFF', backgroundColor: '#1A76F8' }} color='primary'>
                    <ArrowForward />
                </IconButton>
            </div>
        </div>
    )
}