import { Box, IconButton, Slide } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CssTextField } from '../text-field';
import { ArrowForward } from '@mui/icons-material';
import { WaveIcon } from '../svgIcons';
import YogiSVGIconNoBG from '../../images/shvasa-ai-yogi-nobg.png'
import { useNavigate } from 'react-router-dom';


export const BodyMeasurement = () => {
    const { control, formState: { errors }, getValues, handleSubmit, reset, trigger } = useForm();
    const navigate = useNavigate()
    const onNext = async () => {
        const isSuccess = await trigger();
        console.log("ShvasaAIUser isSusccess", { isSuccess, errors });
        if (isSuccess) {
            const values = getValues();
            console.log("ShvasaAIUser onNext===>>", isSuccess, getValues(), values?.name)
            navigate('/goal')
        }
    }
    return (
        <>
            <div className='font-medium text-2xl text-center px-5'>
                To create a personalized Yoga practice for you, we need some information!
            </div>
            <div className='mt-3 px-5 text-center font-thin' style={{ fontSize: 17 }}>
                Please share your height in feet/inches and provide your current weight in pounds or kilograms.
            </div>
            <div className='my-3'>
                <WaveIcon />
            </div>
            <Box className={`relative mx-5 mt-20 flex flex-col mx-5 w-full`}>
                <div className='w-full px-5'>
                    <div className='relative '>
                        <Controller
                            name="height"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field, value }) => (
                                <div>
                                    <Slide in={true} direction='right' mountOnEnter unmountOnExit>
                                        <CssTextField
                                            {...field}
                                            type="text"
                                            variant="outlined"
                                            name="height"
                                            value={value}
                                            required
                                            placeholder="Height in CM"
                                            size="medium"
                                            className="w-full z-10"
                                            InputProps={{
                                                style: {
                                                    borderRadius: 30
                                                }
                                            }}
                                            style={{ background: 'white', borderRadius: 30 }}
                                            error={errors?.height?.type === 'required'}
                                        />
                                    </Slide>
                                    {
                                        errors?.height?.type === 'required'
                                            ? <div className='px-5 text-red-500 text-xxs my-2'>Height is require</div>
                                            : ''
                                    }
                                </div>
                            )}
                        />
                        <div className='text-right flex justify-end absolute right-5 -top-20 z-5'>
                            <img src={YogiSVGIconNoBG} />
                        </div>
                    </div>
                    <div className='my-1'>&nbsp;</div>
                    <Controller
                        name="weight"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field, value }) => (
                            <div>
                                <Slide direction='right' mountOnEnter unmountOnExit in={true}>
                                    <CssTextField
                                        {...field}
                                        type="text"
                                        variant="outlined"
                                        name="weight"
                                        value={value}
                                        required
                                        placeholder="Weight in KG"
                                        size="medium"
                                        className="w-full"
                                        InputProps={{
                                            style: {
                                                borderRadius: 30
                                            }
                                        }}
                                        style={{ background: 'white', borderRadius: 30 }}
                                        error={errors?.weight?.type === 'required'}
                                    /></Slide>
                                {
                                    errors?.height?.type === 'required'
                                        ? <div className='px-5 text-red-500 text-xxs my-2'>Weight is require</div>
                                        : ''
                                }
                            </div>
                        )}
                    />
                </div>
                <div className='flex justify-center mt-3'>
                    <IconButton onClick={onNext} style={{ backgroundColor: 'rgba(26, 118, 248, 1)' }}>
                        <ArrowForward htmlColor='#FFF' />
                    </IconButton>
                </div>
            </Box>
        </>
    )

}