import { createSlice } from "@reduxjs/toolkit";

const shvasaChatAISlice = createSlice({
    name: "chatAI",
    initialState: {
        initiated: false,
        name: null,
        gender: null,
        age: null,
        email: null,
        issueType: null,
        issueName: null,
        isLoading: false,
        convo: [],
        typing: false,
        currentFlow: {
            flowName: null,
            step: null,
            data: {},
            flowData: null,
            values: {}
        }

    },
    reducers: {
        updateUserInfo: (state, action) => {
            state = { ...state, ...action.payload }
            return state;
        },
        updateMedicalIssueInfo: (state, action) => {
            console.log("updateMedicalIssueInfo redux", action.payload, { ...state, ...action.payload })
            state = { ...state, ...action.payload }
            return state;
        },
        updateConvo: (state, action) => {
            state.convo = [...state.convo, action.payload]
        },
        updateStepConvo: (state, action) => {
            const { step } = action.payload || {};
            const _convo = state.convo.map((c) => {
                console.log("STEp{===>", step, c, action.payload);
                if (c.step === step) {
                    c = action.payload
                }
                return c;
            })
            state.convo = _convo
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        clearConvo: (state, action) => {
            state.convo = [];
        },
        setTyping: (state, { payload }) => {
            state.typing = payload
        },
        updateFlow: (state, { payload }) => {
            state.currentFlow = { ...state.currentFlow, ...payload };
        },
        updateFlowData: (state, { payload }) => {
            const { key, value, tagName } = payload || {};
            const data = state.currentFlow?.data ? state.currentFlow?.data[key] : null;
            if (data) {
                const exists = state.currentFlow.data[key].includes(value)
                if (!exists) {
                    state.currentFlow.data[key].push(value)
                } else {
                    state.currentFlow.data[key] = state.currentFlow.data[key].filter((v) => v !== value);
                }
            } else {
                // let _data = {};
                // _data[key] = [value];
                if (!state?.currentFlow?.data) {
                    state.currentFlow.data = {}
                }
                state.currentFlow.data[key] = [value]
            }
            const values = state.currentFlow.values;
            let _data = values || {};
            if (_data[tagName]) {
                _data[tagName].push(value)
            } else {
                _data[tagName] = [value];
            }

            state.currentFlow.values = { ...values, ..._data }
            state.currentFlow.data = { ...state.currentFlow.data }
        }
    }
});

export const {
    updateUserInfo, updateMedicalIssueInfo, updateConvo, setIsLoading, clearConvo, setTyping, updateFlow, updateFlowData, updateStepConvo
} = shvasaChatAISlice.actions
export default shvasaChatAISlice.reducer;