import React from 'react';
import { ShareSocial } from 'react-share-social'
const style = {
    root: {
        borderRadius: 3,
        border: 0,
        color: '#FFF',
        padding: 0
    },
    copyContainer: {
        border: '1px solid blue',
        background: '#FFF',
        color: "#4d4d4d",
    },
    title: {
        color: '#4d4d4d',
    },
    copyUrl: {
        color: "#4d4d4d"
    }
};
export const ShareWidget = ({ url }) => (
    <ShareSocial
        url={url}
        socialTypes={['facebook', 'twitter', 'whatsapp']}
        style={style}
        title={"Share with friends"}
        onSocialButtonClicked={(arg) => console.log("social bttn clicked", arg)}
    />
)

