import { Add } from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
export const FlowGenerator = () => {
    const [flowData, setFlowData] = useState({})
    const [type, setType] = useState(null)

    const { formState, getValues, register, trigger } = useForm()
    useEffect(() => {
        console.log("formState", formState)
    }, [formState])
    const setState = (data) => {
        setFlowData((prev)=>({...prev, ...data}))
    }
    const addFlowData = () => {
        
    }

    const changeType = (e) => {
        setType(e?.target?.value)
    }

    const onActionChange = (data) => {
        setState(data)
    }

    return (
        <Card className='w-full'>
            <CardContent className='flex flex-col gap-2'>
                <TextField placeholder='Text' {...register('text')} />
                <TextField placeholder='Tag Name' {...register('tagName')} />
                <Select displayEmpty={true} renderValue={(val) => !val ? `Select Card Type` : val} onChange={changeType} defaultValue={type} placeholder='Select Type' {...register('type', { onChange: changeType })} >
                    <MenuItem value={"text"}>Text</MenuItem>
                    <MenuItem value={"action"}>Action</MenuItem>
                </Select>
                {
                    type === 'action'
                        ? <ActionForm onChange={onActionChange} />
                        : ''
                }
            </CardContent>
            <CardActions>
                <Button variant='contained' color='primary' disableElevation onClick={addFlowData}>Add Step </Button>
            </CardActions>
        </Card>
    )
}

const ActionForm = () => {
    const [data, setData] = useState({
        label: null,
        id: null,
        type: null,
    })
    const [count, setCount] = useState(1)
    const setState = (data) => {
        setData((prev) => ({ ...prev, ...data }))
    }
    const onChangeType = (e) => {
        setState({
            type: e?.target?.value
        })
    }
    const items = [];
    for (let i = 0; i < count; i++)items.push(i)
    console.log("arrya count", count, items,  );
    return(<>
        {items.map(
            item => (<div key={`action-step-${item}`} className='my-5 flex flex-col gap-1'>
                <div className='w-full flex items-center justify-center'> Action {item+1} <div className='h-0 border-b '></div></div>
                <Select onChange={onChangeType} defaultValue={data?.type} renderValue={(val)=>!val?"Select Action type": val}>
                    <MenuItem value='button'>Button</MenuItem>
                    <MenuItem value='input'>TextField</MenuItem>
                    <MenuItem value='chip'>chip</MenuItem>
                </Select>
                <TextField placeholder='variant' />
                <TextField placeholder='style' />
            </div>))
        }
        <div>
            <Button onClick={()=>setCount(count+1)} startIcon={<Add />}>Add Step</Button>
        </div>
    </>
    );
}