import React from 'react';
import { moveNextStep, registerAndBook, scrollToBottomConvo, sendChatAIMessageText, setStepData, updateCurrentStepValue } from './ShvasaChatAIConvo';
import { setIsLoading, setTyping, updateConvo, updateUserInfo, updateMedicalIssueInfo, updateStepConvo, updateFlow, updateFlowData } from './shvasaChatAISlice';
import { AppDispatch } from '../configureStore';
import { delayInMs } from '../utils/utils';
import { CHATGPT_POSE_PROMPT, CREATE_SCHEDULE_CONFIRMATION_TEXT, CURATED_CONTENT_TEXT, DONT_CREATE_WEEKLY_SCHEDULE_TEXT, DON_WANT_FREE_TRIAL, FREE_TRIAL_SCHEDULE, MESSAGE_DELAY_MS, SCHEDULE_CLASS_CONFIRMATION_TEXT, UNLOCK_FREE_TRIAL, MENTAL_HEALTH_ISSUE_HELP, fitnessGoals } from './constants';
import { requestAPI } from './api_interaction';
import { ChatBotAPI } from '../apis/chatbot.api';
import { templatization } from '../utils/strings';
import { UserAPIInstance } from '../apis/user.api';
import { medicalIssueWithIcons } from '../utils/iconutils';
import { ListItemComponent, ListComponent } from './ui/list_component'
import { CONSTANTS, PHYSICAL_HEALTH_ISSUE_HELP } from './constants'
import { ListItem } from '@mui/material';
import KaphaIcon from '../images/kapha-icon.svg'
import PittaIcon from '../images/pitta-icon.svg'
import VataIcon from '../images/vata-icon.svg'
import { FLOW_DATA } from './flow_data';
import { HOME_FLOW_STEP } from './home_flow_step';

const ayurvedicIcon = (key) => {
    let _src = null;
    if (key === 'vata') {
        _src = VataIcon;
    } else if (key === 'kapha') {
        _src = KaphaIcon;
    } else if (key === 'pitta') {
        _src = PittaIcon;
    }
    return _src;
}

const updateIssue = (data, chatAI) => {
    const { name, videoURL, item } = data || {};
    const { currentFlow } = chatAI || {};
    const { step, flowData: { steps } } = currentFlow || {};
    const cst = (steps || [])[step]
    AppDispatch(updateMedicalIssueInfo({
        issueName: name || "",
        videoURL: videoURL,
        selectedIssue: data,
    }))
    sendChatAIMessageText(name)
    console.log("CST++>", cst, data);
    AppDispatch(updateConvo({
        isYogi: true,
        text: templatization(data?.instructions || CURATED_CONTENT_TEXT, chatAI)
    }))
    //AppDispatch(updateFlowData({key:'yogaHelp', value: data?.yogaHelp, tagName: 'yogaHelp' }))
    moveNextStep(step + 1)
}

export const physicalmedicalIssue = {
    'physicalmedicalIssue': {
        steps: [
            {
                step: 1,
                isYogi: true,
                text: 'Hey {{name}}\n  {{PHYSICAL_HEALTH_ISSUE_HELP}}',
                onLoad: async (chatAI) => {
                    try {
                        if (chatAI.isLoading) {
                            return;
                        }
                        AppDispatch(setIsLoading(true));
                        const response = await UserAPIInstance.getMedicalIssues();
                        const _text = templatization('Hey {{name}}\n  {{PHYSICAL_HEALTH_ISSUE_HELP}}', { ...chatAI, PHYSICAL_HEALTH_ISSUE_HELP, });
                        AppDispatch(updateUserInfo({ issuesList: response?.body || [] }))
                        const renderComp = (
                            <>
                                {_text}
                                {
                                    medicalIssueWithIcons({ conditionsList: response?.body?.filter((c) => (c?.issueType || c?.type) === (chatAI.issueType))?.filter((cond) => cond?.name?.toLowerCase() !== 'none') || [] })?.map((d) => {
                                        return (
                                            <ListItemComponent item={d} icon={d?.icon} svgIcon={d?.svgIcon} title={d?.name} onClick={() => updateIssue(d, chatAI)} />
                                        );
                                    })
                                }
                            </>
                        )
                        AppDispatch(updateStepConvo({
                            isYogi: true,
                            text: renderComp,
                            step: chatAI?.step || 1
                        }))
                    } catch (error) {
                        console.log("Error ", error, error.stack)
                    }
                    AppDispatch(setIsLoading(false));
                }
            },
            {
                step: 2,
                isYogi: true,
                text: "Our expert Yogi has put together an amazing blend of {{yogaHelp}}, all tailored to help you conquer {{issueName}}!",
                onLoad: async (chatAI) => {
                    try {
                        if (chatAI.isLoading) {
                            return;
                        }
                        AppDispatch(setIsLoading(true))
                        console.log("chatAI->ONLOADD_+++++++>>>>", chatAI)
                        const yogaHelp = chatAI?.selectedIssue?.yogaHelp || ""
                        const promptText = `Recommend asana and breathing for a ${chatAI.age} years old ${chatAI.gender} dealing with ${chatAI?.issueName}. Group them by the type (asana and breathing)? Must have result as json containing type(asana and breathing) as key and value as list of asana. Must have englisName, sanskritName,hinglishName and description of each asana.`;
                        const { name, age, gender, issueName } = chatAI || {};

                        const response = await ChatBotAPI.chatGPTHelp({ text: promptText, name, age, gender, issueName, withImage: true, yogaHelp: yogaHelp.split(",")?.map(t => t.trim()).filter(t => t !== 'nutrition'), flowName: 'yoga_weekly_routine' })
                        const { botResponse, imageURL, videoURL, videoThumbnailURL, resultList } = response?.body || {}
                        console.log("videoURL===>", videoURL);

                        console.log("chat typing started", +(new Date()));
                        AppDispatch(updateUserInfo({
                            'yoga_weekly_routine_botResponse': botResponse
                        }))
                        await delayInMs(() => {
                            const tabs = yogaHelp.split(",")?.map(t => t.trim()).filter(t => t !== 'nutrition')
                            let panels = [];
                            tabs.forEach((t) => {
                                panels.push({ type: 'swiper', items: resultList[t] })
                            })
                            AppDispatch(updateConvo({
                                type: 'tab',
                                text: botResponse,
                                isYogi: true,
                                tabs: tabs,
                                panels: panels
                            }));
                            console.log("chat typing stopped", +(new Date()));
                            scrollToBottomConvo();
                        }, MESSAGE_DELAY_MS);
                        moveNextStep()
                    } catch (error) {
                        console.log("EROOOOOORRRR", error, error.stack)
                    }
                    AppDispatch(setIsLoading(false))
                }
            },
            {
                step: 3,
                text: CREATE_SCHEDULE_CONFIRMATION_TEXT,
                type: 'action',
                actions: [{
                    label: 'yes',
                    variant: 'contained',
                    action: () => {
                        sendChatAIMessageText("Yes"); moveNextStep(3)
                    },
                    type: 'button'
                },
                {
                    label: 'no',
                    action: () => {
                        sendChatAIMessageText("No");
                        moveNextStep(4)

                    },
                    type: 'button'

                }],
                text: CREATE_SCHEDULE_CONFIRMATION_TEXT,
                isYogi: true,

            },
            {
                step: 4,
                type: 'action',
                text: UNLOCK_FREE_TRIAL,
                isYogi: true,
                layout: 'col',
                blurBg: true,
                condition: (chatAI) => {
                    return !chatAI?.email?.length;
                },
                actions: [{
                    type: 'input',
                    label: 'Email',
                    id: 'weekly-plan-email'
                }, {
                    type: 'button',
                    label: 'Submit',
                    id: 'weekly-plan-email',
                    variant: 'contained',
                    action: (ref, id, cf, chatAI, email) => {
                        const inputText = email || document.getElementById(id)?.value || "";
                        const emailConfirmation = async (email) => {
                            const { name, age, gender, issueName } = chatAI || {};
                            AppDispatch(setIsLoading(true))
                            const response = ChatBotAPI.chatGPTHelp({ text: templatization(CHATGPT_POSE_PROMPT, chatAI), name, age, gender, issueName, createPlan: true, email, sendEmail: true, flowName: 'yoga_weekly_routine' })
                            AppDispatch(setIsLoading(false))
                            const _text = (response?.body?.botResponse || "")
                            AppDispatch(updateConvo({ text: "We have sent you an email, Please check your email for full plan", isYogi: true }))
                            const { yogaHelp } = chatAI?.selectedIssue || "";
                            const yogaHelpList = yogaHelp.split(",").map((t) => t.trim()?.toLowerCase())
                            console.log("yogaHelpList", yogaHelpList, 'nutrition' in yogaHelpList);
                            if (yogaHelpList.includes('nutrition')) {
                                moveNextStep(5)
                            } else {
                                moveNextStep(6)
                            }
                            AppDispatch(setIsLoading(false))
                        }
                        if (inputText?.trim().length > 2) {
                            sendChatAIMessageText(inputText);
                            AppDispatch(updateUserInfo({ email: inputText }))
                            setTimeout(() => {
                                emailConfirmation(inputText, 'yoga_weekly_routine');
                            }, 300)
                        }
                    }
                }]

            },
            {
                step: 5,
                text: DONT_CREATE_WEEKLY_SCHEDULE_TEXT,
                type: 'action',
                isYogi: true,
                actions: [{
                    label: 'Yes',
                    type: 'button',
                    variant: 'contained',
                    action: (ref, id, cf, chatAI) => {
                        sendChatAIMessageText("Yes");
                        const _text = templatization(FREE_TRIAL_SCHEDULE, chatAI);
                        console.log("====Email is not present?", chatAI?.email?.length);
                        setTimeout(AppDispatch(updateConvo({
                            text: _text,
                            type: "action",
                            layout: 'col',
                            isYogi: true,
                            condition: (chatAI) => {
                                return !chatAI?.email?.length;
                            },
                            actions: [{
                                type: 'input',
                                label: 'Email',
                                id: 'free-schedule-email'
                            }, {
                                type: 'button',
                                label: 'Submit',
                                variant: 'contained',
                                id: 'free-schedule-email',
                                action: (ref, id, cf, chatAI, email) => {
                                    const inputText = email || document.getElementById(id)?.value || "";
                                    console.log("templatization(FREE_TRIAL_SCHEDULE", inputText);
                                    if (inputText?.trim().length > 2) {
                                        sendChatAIMessageText(inputText);
                                        AppDispatch(updateUserInfo({ email: inputText }))
                                        setTimeout(() => {
                                            registerAndBook(inputText);
                                        }, 300)
                                    }
                                }
                            }]
                        })), 300);
                    }
                }, {
                    label: 'no',
                    type: 'button',
                    action: () => {
                        sendChatAIMessageText('No')
                        setTimeout(() => {
                            AppDispatch(updateConvo({
                                text: (<>
                                    <div className='mb-5'>{DON_WANT_FREE_TRIAL}</div>
                                    <div>
                                        {HOME_FLOW_STEP?.text}
                                    </div>
                                </>),
                                isYogi: true
                            }))
                            scrollToBottomConvo();
                        }, 300)
                    }
                }]
            },
            {
                step: 6,
                text: `Unlock the key to managing {{issueName}} with the power of personalized nutrition! 
                We will craft a tailored 7-day nutrition plan just for you. As a bonus, enjoy a 7-day free trial of our guided yoga classes – no credit card required.
Are you ready to take control of your health? Let's create your custom plan together!`,
                type: 'action',
                actions: [{
                    type: 'button',
                    action: async () => {
                        sendChatAIMessageText('yes')
                        await registerAndBook(null, false); // create user and update accesstoken on redux
                        const flowName = 'PRAKRITI_ANALYSIS'
                        const text = `Woohoo! Three free classes with our awesome yoga teachers are all yours! 
                        Just tap the <calendar> icon below to book your blissful sessions at your convenience.
Now, let's keep the good vibes flowing with your personalized nutrition plan!`;
                        AppDispatch(updateConvo({
                            isYogi: true,
                            text: text
                        }))
                        AppDispatch(updateFlow({ flowName: flowName, step: 0, flowData: FLOW_DATA[flowName] }))
                        scrollToBottomConvo();
                    },
                    value: "yes",
                    label: 'yes'
                }, {
                    type: 'button',
                    action: () => {
                        moveNextStep(6)
                    },
                    value: "no",
                    label: 'no'
                }]
            },
            {
                step: 7,
                type: 'action',
                text: SCHEDULE_CLASS_CONFIRMATION_TEXT,
                actions: [{
                    label: 'yes',
                    variant: 'contained',
                    action: async () => {
                        sendChatAIMessageText("Yes");
                        await registerAndBook()
                        AppDispatch(updateConvo({
                            text: (<>
                                <div className='mb-5'>Is there anything else we can assist you with?</div>
                                <div>
                                    {HOME_FLOW_STEP?.text}
                                </div>
                            </>),
                            isYogi: true
                        }))
                    },
                    type: 'button'
                },
                {
                    label: 'no',
                    action: () => {
                        sendChatAIMessageText("No");
                        moveNextStep(7)
                    },
                    type: 'button'
                }]
            },
            {
                step: 8,
                text: (<>
                    <div className='mb-5'>{DON_WANT_FREE_TRIAL}</div>
                    <div>
                        {HOME_FLOW_STEP?.text}
                    </div>
                </>),

            }
        ],
    }
}