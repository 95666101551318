import React from 'react';


export const MaleSVGIcon = () => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="120"
        fill="none"
        viewBox="0 0 97 98"
    >
        <g filter="url(#filter0_d_1984_26605)" opacity="0.9">
            <path
                fill="#fff"
                d="M48.25 75.168c16.569 0 30-13.432 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30z"
            ></path>
            <path
                stroke="#FC8F00"
                strokeMiterlimit="10"
                strokeWidth="2"
                d="M44.866 57.5a9.585 9.585 0 100-19.17 9.585 9.585 0 000 19.17zM51.635 41.147l8.458-7.332"
            ></path>
            <path
                stroke="#FC8F00"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M52.143 33.163l9.076-.663-1.645 8.767"
            ></path>
        </g>
        <defs>
            <filter
                id="filter0_d_1984_26605"
                width="120"
                height="120"
                x="0.25"
                y="0.5"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                ></feColorMatrix>
                <feOffset dy="4"></feOffset>
                <feGaussianBlur stdDeviation="9"></feGaussianBlur>
                <feComposite in2="hardAlpha" operator="out"></feComposite>
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                <feBlend
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_1984_26605"
                ></feBlend>
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_dropShadow_1984_26605"
                    result="shape"
                ></feBlend>
            </filter>
        </defs>
    </svg>
    );
}

export const FemaleSVGIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="120"
            fill="none"
            viewBox="0 0 97 98"
        >
            <g filter="url(#filter0_d_1984_26605)" opacity="0.9">
                <path
                    fill="#fff"
                    d="M48.25 75.168c16.569 0 30-13.432 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30z"
                ></path>
                <path
                    stroke="#FC8F00"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                    d="M44.866 57.5a9.585 9.585 0 100-19.17 9.585 9.585 0 000 19.17zM51.635 41.147l8.458-7.332"
                ></path>
                <path
                    stroke="#FC8F00"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M52.143 33.163l9.076-.663-1.645 8.767"
                ></path>
            </g>
            <defs>
                <filter
                    id="filter0_d_1984_26605"
                    width="96"
                    height="97"
                    x="0.25"
                    y="0.5"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="9"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1984_26605"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1984_26605"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>
    );
}

export const NonBinarySVGIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="120"
            fill="none"
            viewBox="0 0 97 98"
        >
            <g filter="url(#filter0_d_1984_26628)" opacity="0.9">
                <path
                    fill="#fff"
                    d="M48.25 75.168c16.569 0 30-13.432 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30z"
                ></path>
                <path
                    stroke="#FC8F00"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M48.25 56.5a7.817 7.817 0 100-15.634 7.817 7.817 0 000 15.634zM48.61 40.695V31.5M44.053 37.76l8.366-4.36M52.42 37.76l-8.367-4.36"
                ></path>
            </g>
            <defs>
                <filter
                    id="filter0_d_1984_26628"
                    width="96"
                    height="97"
                    x="0.25"
                    y="0.5"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="9"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1984_26628"
                    ></feBlend>
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1984_26628"
                        result="shape"
                    ></feBlend>
                </filter>
            </defs>
        </svg>)
}