export const getNameInitials = (name) => {
    const _name = name.split(" ");
    if (_name.length > 1) return _name.splice(0, 2).map(item => item[0]).join("")
    else return name.substr(0, 2);
}

export const getFirstName = (name) => {
    return name?.split(' ')[0] || ''
}

export const truncateStr = (str, size) => {
    return str.length > size ? str.substring(0, size) + "..." : str;
}

export const toDoubleDigit = (number) => {
    return number.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
}

export function templatization(text, data) {
    const keys = Object.keys(data);
    console.log("templatization===<", data, data['name'], data['issueName']);
    let _text = text;
    if (!_text?.replace) {
        return _text;
    }
    keys.forEach((k) => {
        _text = _text.replace("{{" + k + "}}", data[k]);
    })
    return _text;
}