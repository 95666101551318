import React from 'react';
import { moveNextStep, registerAndBook, scrollToBottomConvo, sendChatAIMessageText, setStepData, updateCurrentStepValue } from './ShvasaChatAIConvo';
import { setIsLoading, setTyping, updateConvo, updateUserInfo, updateMedicalIssueInfo, updateStepConvo, updateFlow, updateFlowData } from './shvasaChatAISlice';
import { AppDispatch } from '../configureStore';
import { delayInMs } from '../utils/utils';
import { CHATGPT_POSE_PROMPT, CREATE_SCHEDULE_CONFIRMATION_TEXT, CURATED_CONTENT_TEXT, DONT_CREATE_WEEKLY_SCHEDULE_TEXT, DON_WANT_FREE_TRIAL, FREE_TRIAL_SCHEDULE, MESSAGE_DELAY_MS, SCHEDULE_CLASS_CONFIRMATION_TEXT, UNLOCK_FREE_TRIAL, MENTAL_HEALTH_ISSUE_HELP, fitnessGoals } from './constants';
import { requestAPI } from './api_interaction';
import { ChatBotAPI } from '../apis/chatbot.api';
import { templatization } from '../utils/strings';
import { UserAPIInstance } from '../apis/user.api';
import { medicalIssueWithIcons } from '../utils/iconutils';
import { ListItemComponent, ListComponent } from './ui/list_component'
import { CONSTANTS, PHYSICAL_HEALTH_ISSUE_HELP } from './constants'
import { ListItem } from '@mui/material';

import KaphaIcon from '../images/kapha-icon.svg'
import PittaIcon from '../images/pitta-icon.svg'
import VataIcon from '../images/vata-icon.svg'
import { HOME_FLOW_STEP } from './home_flow_step'
import { physicalmedicalIssue } from './physicial-flow-data'
import { YogaGoalMapping } from './goals_icon_mapping';

const ayurvedicIcon = (key) => {
    let _src = null;
    if (key === 'vata') {
        _src = VataIcon;
    } else if (key === 'kapha') {
        _src = KaphaIcon;
    } else if (key === 'pitta') {
        _src = PittaIcon;
    }
    return _src;
}

export const updateIssue = (data, chatAI) => {
    const { name, videoURL, item } = data || {};
    const { currentFlow } = chatAI || {};
    const { step, flowData: { steps } } = currentFlow || {};
    const cst = (steps || [])[step]
    AppDispatch(updateMedicalIssueInfo({
        issueName: name || "",
        videoURL: videoURL,
        selectedIssue: data,
    }))
    sendChatAIMessageText(name)
    console.log("CST++>", cst, data);
    AppDispatch(updateConvo({
        isYogi: true,
        text: data?.instructions || CURATED_CONTENT_TEXT
    }))
    //AppDispatch(updateFlowData({key:'yogaHelp', value: data?.yogaHelp, tagName: 'yogaHelp' }))
    moveNextStep(step + 1)
}

export const FLOW_DATA = {
    'nutrition': {
        steps: [
            {
                step: 1,
                text: 'Before creating nutrition plan , we need to test your dosha test, would you like to take dosha test first ? ',
                type: 'action',

                actions: [{
                    type: 'button',
                    label: 'Yes',
                    variant: "contained",
                    action: () => {
                        sendChatAIMessageText("Yes");
                        const flowName = 'PRAKRITI_ANALYSIS'
                        AppDispatch(updateFlow({ flowName: flowName, step: 0, flowData: FLOW_DATA[flowName] }))
                    },
                }, {
                    type: 'button',
                    label: 'No',
                    action: () => {
                        sendChatAIMessageText("No");
                        moveNextStep(1)
                    },
                }]
            },
            {
                step: 2,
                text: `Hey {{name}}, 
            To create a personalized nutrition plan, we need some information!

            Please share your height in centimeters and provide your current weight in kg.`,
                type: 'action',
                layout: 'col',
                tagName: "measurement",
                actions: [{
                    type: 'input',
                    label: "Height in centimeters",
                    id: 'height'
                }, {
                    type: 'input',
                    label: "Weight in Kilogram",
                    id: 'weight'
                }, {
                    type: 'button',
                    id: ['height', 'weight'],
                    label: "Submit",
                    variant: 'contained',
                    action: (ref, ids) => {
                        const [height, weight] = ids?.map((id) => document.getElementById(id)?.value);
                        sendChatAIMessageText(`Height: ${height}cm\n Weight: ${weight}kg`)
                        setStepData({ height, weight });
                        moveNextStep(2)
                    }
                }],

            },
            {
                step: 3,
                text: 'Ah, thank you, my friend, for sharing your height and weight with me. now please choose a goal.',
                tagName: 'goal',
                onLoad: async (chatAI) => {
                    try {
                        console.log("CHAT=>AID+++>>", chatAI);
                        if (chatAI.isLoading) {
                            return;
                        }
                        AppDispatch(setIsLoading(true));
                        const data = await ChatBotAPI.getYogaGoals();
                        const goals = data?.body || [];

                        let fitnessQs = goals.map((item) => {
                            item.svgIcon = YogaGoalMapping[item?.name]?.svgIcon;
                            console.log(" item?.name", item?.name, YogaGoalMapping[item?.name]);
                            return item;
                        })
                        const updateValue = (item) => {
                            const text = item?.name || item?.text
                            setStepData(text);
                            updateCurrentStepValue()
                            moveNextStep(3)
                        }

                        const renderComp = (
                            <>
                                <div>Ah, thank you, my friend, for sharing your height and weight with me. now please choose a goal.</div>
                                {
                                    fitnessQs?.map((item) => <ListItem key={item?._id} button divider onClick={() => updateValue(item)}>
                                        <div className=' flex gap-2 items-center'>
                                            <div className='svg-wrapper-h50'>
                                                {item?.svgIcon}
                                            </div>
                                            <div>{item?.name || item?.text}</div>
                                        </div>
                                    </ListItem>)
                                }
                            </>
                        )

                        await delayInMs(() => {
                            AppDispatch(updateStepConvo({
                                isYogi: true,
                                text: renderComp,
                                step: 3
                            }))
                            scrollToBottomConvo()
                        }, MESSAGE_DELAY_MS)
                    } catch (error) {

                    }
                    AppDispatch(setIsLoading(false));
                }
            },
            {
                step: 4,
                text: 'Got it, {{name}}. Now, before I create a 7-day nutrition plan for you, I would like to know a few things that will help me give you a perfect diet plan.So, are you a...',
                type: 'action',
                layout: 'col',
                tagName: 'foodType',
                actions: [
                    {
                        label: 'Vegan',
                        type: 'button',
                        variant: 'outlined',
                        action: (ref, id) => {
                            setStepData("Vegan")
                            updateCurrentStepValue()
                            moveNextStep(4)
                        },
                        onClickValue: {

                        }
                    },
                    {
                        label: 'Vegetarian',
                        type: 'button',
                        variant: 'outlined',
                        action: (ref, id) => {
                            setStepData("Vegetarian");
                            updateCurrentStepValue()
                            moveNextStep(4)
                        }
                    },
                    {
                        label: 'Non-vegetarian',
                        type: 'button',
                        variant: 'outlined',
                        action: (ref, id) => {
                            setStepData("Non-vegetarian");
                            updateCurrentStepValue()
                            moveNextStep(4)
                        }
                    }
                ]
            },
            {
                step: 5,
                text: 'Good to know Captain {{foodType}}. So do you have any Allergy ?',
                type: 'action',
                layout: 'wrap',
                tagName: "allergies",
                actions: [
                    {
                        type: 'chip',
                        label: 'None',
                        action: (ref, id) => {
                            setStepData('None')
                        }
                    },
                    {
                        type: 'chip',
                        label: 'Food Allergies',
                        action: (ref, id) => {
                            setStepData('Food Allergies')
                        }
                    }, {
                        type: 'chip',
                        label: 'Sesame Allergy',
                        action: (ref, id) => {
                            setStepData('Sesame Allergy')
                        }
                    },
                    {
                        type: 'chip',
                        label: 'Soy Allergy',
                        action: (ref, id) => {
                            setStepData('Soy Allergy')
                        }
                    }, ,
                    {
                        type: 'chip',
                        label: 'Egg Allergy',
                        action: (ref, id) => {
                            setStepData('Egg Allergy')
                        }
                    },
                    ,
                    // {
                    //     type: 'chip',
                    //     label: 'Other',
                    //     action: (ref, id) => {
                    //         setStepData('Other')
                    //     }
                    // },
                    ,
                    {
                        type: 'button',
                        label: 'Next',
                        variant: 'contained',
                        className: 'mt-20',
                        style: { marginTop: 50 },
                        action: (ref, id) => {
                            updateCurrentStepValue()
                            moveNextStep(5)
                        }
                    },
                ]
            },
            {
                step: 6,
                text: `Rest assured, I'll avoid suggesting any foods or ingredients that may pose a risk based on your allergies.`,
                onLoad: () => {
                    moveNextStep(6)
                }
            }, {
                step: 7,
                text: `{{name}}, What are your cuisine or personal preferences when it comes to food?`,
                type: 'action',
                layout: 'col',
                tagName: "cuisine",
                actions: [
                    {
                        type: 'input',
                        label: "Cusine",
                        id: 'cuisine-preference',
                    },
                    {
                        type: 'button',
                        variant: 'contained',
                        label: 'submit', variant: 'contained', style: { marginTop: 10 },
                        id: 'cuisine-preference',
                        action: (ref, id) => {
                            const inputEl = document.getElementById(id)
                            setStepData(inputEl.value)
                            updateCurrentStepValue('nutrition-step-')
                            moveNextStep(7)
                        }
                    }
                ]
            }, {
                step: 8,
                text: `Ah, It sounds like your taste buds have mastered the art of a more with those classic {{cuisine}} delights.`,
                onLoad: () => {
                    moveNextStep(8)
                }
            },
            {
                step: 9,
                text: 'Which spiciness level resonates with your enlightened taste!',
                type: 'action',
                layout: 'wrap',
                tagName: 'spiceType',
                actions: [
                    {
                        type: 'button',
                        variant: 'outlined',
                        label: 'Tickle, Not Torch',
                        //value:'',
                        fullWidth: true,
                        action: (ref, id) => {
                            setStepData('Tickle, Not Torch')
                            updateCurrentStepValue('nutrition-step-')
                            moveNextStep(9)
                        }
                    }, {
                        type: 'button',
                        variant: 'outlined',
                        label: 'Tango of Tastebuds',
                        className: 'my-2',
                        fullWidth: true,
                        action: (ref, id) => {
                            setStepData("Tango of Tastebuds")
                            updateCurrentStepValue('nutrition-step-')
                            moveNextStep(9)
                        }
                    }, {
                        type: 'button',
                        variant: 'outlined',
                        label: 'Flame Throwdown',

                        fullWidth: true,
                        action: (ref, id) => {
                            setStepData("Flame Throwdown")
                            updateCurrentStepValue('nutrition-step-')
                            moveNextStep(9)
                        }
                    },
                ]
            },
            {
                step: 10,
                text: 'Ah, excellent choice!',
                onLoad: async (chatAI) => {
                    try {
                        const { values } = chatAI?.currentFlow || {};
                        const { convo, isLoading } = chatAI || {};
                        const nutriFlow = convo.slice(-2).some((val) => val.nutriFlow);
                        console.log("nutriFlow", nutriFlow, convo)
                        if (nutriFlow || isLoading) {
                            return;
                        }
                        console.log("chatAIVALUE", chatAI)
                        let promptText = templatization('create a nutrition weekly meal plan form me, i am a {{foodType}}', values)
                        if (values?.length && 'None' in values['allergies']) {
                            promptText += 'having no allergies'
                        } else {
                            promptText += ` having ${values['allergies'].join(',')} allergies `
                        }
                        promptText += ' prefer {{foodType}}'
                        promptText += `and spiciness level ${values['spiceType'].join(',')}. create a quick small paragraph`
                        promptText = templatization(promptText, { ...(chatAI || {}), ...(values || {}) })
                        AppDispatch(setIsLoading(true))

                        const response = ChatBotAPI.chatGPTHelp({ text: promptText, })
                        if (response?.body?.botResponse) {
                            let textResult =
                                response?.body?.botResponse || "";
                            textResult = textResult.substring(0, Math.min(textResult?.length, 200)) + "..."
                            AppDispatch(updateConvo({
                                nutriFlow: true,
                                text: templatization(`We have sent you an email, Please check your email for full plan`, { ...(chatAI || {}), ...(values || {}) }),
                                isYogi: true
                            }))
                            scrollToBottomConvo();
                        }
                    } catch (err) {
                        console.log("Error while adding bot response", err, err.stack,)
                    }
                    AppDispatch(setIsLoading(true))
                    moveNextStep(10)
                }
            }, {
                step: 11,
                text: ` Kindly provide your email, and I will share it with you. Rest assured, your privacy is extremely secure—so secure that even Edward Snowden couldn't crack it with a 10,000-character password! 😄`,
                type: 'action',
                tagName: 'email',
                layout: 'col',
                condition: (chatAI) => {
                    return !chatAI?.email?.length;
                },
                actions: [
                    {
                        type: 'input',
                        id: 'nutrition-email',
                        label: 'Enter email',
                    }, {
                        type: 'button',
                        id: 'nutrition-email',
                        label: 'submit', variant: 'contained', style: { marginTop: 10 },
                        variant: 'contained',
                        action: async (ref, id, currentFlow, chatAI, email) => {
                            const inputEl = document.getElementById(id)
                            email = email || inputEl.value?.trim();
                            if (!email?.length) {
                                return;
                            }
                            setStepData(email)
                            updateCurrentStepValue()
                            const { values } = currentFlow || {};
                            let promptText = templatization('create a nutrition weekly meal plan form me, i am a {{foodType}}', values)
                            if (values?.length && 'None' in values['allergies']) {
                                promptText += 'having no allergies'
                            } else {
                                promptText += ` having ${values['allergies']?.join(',')} allergies `
                            }
                            promptText += ' prefer {{foodType}}'
                            promptText += `and spiciness level ${values['spiceType']?.join(',')}`
                            promptText = templatization(promptText, values)
                            try {
                                AppDispatch(setIsLoading(true))
                                AppDispatch(updateUserInfo({ email }))
                                const response = ChatBotAPI.chatGPTHelp({ email, text: promptText, sendEmail: true, flowName: 'nutrition_plan' })

                            } catch (err) {

                            }
                            AppDispatch(setIsLoading(false))
                            moveNextStep(11)
                        }
                    }
                ]
            }, {
                step: 12,
                text: 'I have shared the 7-day nutrition diet on {{email}}. ',
                onLoad: () => {
                    //moveNextStep(12)
                }
            }, {
                step: 13,
                text: `{{name}},
We have gathered data that clearly shows that people can adhere to this diet more effectively by using our Shvasa application. The app acts as a daily reminder for their diet plan. Additionally, we offer a 7-day free trial, during which they can book three classes and schedule a consultation with our counselor.

Are you interested in trying our 7-day free trial and booking a counseling session on our mobile application?`,
                type: 'action',
                layout: 'col',
                actions: [{
                    type: 'button',
                    variant: 'outlined',
                    label: 'Yes, Book a Consultation Session',
                    action: async () => {
                        setStepData('Yes, Book a Consultation Session')
                        updateCurrentStepValue()
                        AppDispatch(setIsLoading(true))
                        await registerAndBook()
                        AppDispatch(setIsLoading(false))
                        moveNextStep(13)
                    }
                }, {
                    type: 'button',
                    variant: 'contained',
                    label: 'Yes, Book a Class',
                    action: (chatAI) => {
                        setStepData('Yes, Book a Class')
                        updateCurrentStepValue();
                        moveNextStep(14)
                    }
                }, {
                    type: 'button',
                    variant: 'text',
                    label: "Not now",
                    action: () => {
                        setStepData('Not now')
                        updateCurrentStepValue();
                        moveNextStep(15)
                    }
                }
                ]
            }, {
                step: 14,
                name: 'classBooking',
                text: `Great 🎉! 
I have created a 7-day free trial for you. Now, you are being redirected to our class schedule screen.`
            }, {
                step: 15,
                name: 'consultationBooking',
                text: `Woohoo! 🎉I have created a 7-day free trial for you. Now, you'll be able to book 3 live classes and make an appointment with a counselor.To get started, please download our application from this link.`,
                type: 'action',
                actions: [
                    {
                        type: 'link',
                        imgSrc: 'https://assets.website-files.com/60e7f6b5ecd3ae8e0c125cd7/62d7d042df660b1821a202b6_appstore2.webp',
                        href: 'https://apps.apple.com/us/app/shvasa-yoga-in-every-breath/id1610440321',
                        style: { maxHeight: 80 },
                    },
                    {
                        type: 'link',
                        style: { maxHeight: 80 },
                        imgSrc: 'https://assets.website-files.com/60e7f6b5ecd3ae8e0c125cd7/62d7d0516f11a35bec077f0f_playstore2.webp',
                        href: 'https://play.google.com/store/apps/details?id=com.shvasa.shvasa_mobile'
                    }
                ]
            }, {
                step: 16,
                text:
                    (<>
                        <div className='mb-5'>No problem. Is there anything else you need help with?</div>
                        <div>
                            {HOME_FLOW_STEP?.text}
                        </div>
                    </>)
                ,
                isYogi: true
            }
        ]
    },
    ...physicalmedicalIssue,
    'mentalmedicalIssue': {
        steps: [
            {
                step: 1,
                isYogi: true,
                text: 'Hey {{name}}\n  {{MENTAL_HEALTH_ISSUE_HELP}}',
                onLoad: async (chatAI) => {
                    try {
                        if (chatAI.isLoading) {
                            return;
                        }
                        AppDispatch(setIsLoading(true));
                        const response = await UserAPIInstance.getMedicalIssues();
                        const _text = templatization('Hey {{name}}\n  {{MENTAL_HEALTH_ISSUE_HELP}}', { ...chatAI, MENTAL_HEALTH_ISSUE_HELP, });
                        AppDispatch(updateUserInfo({ issuesList: response?.body || [] }))
                        const renderComp = (
                            <>
                                {_text}
                                {
                                    medicalIssueWithIcons({ conditionsList: response?.body?.filter((c) => (c?.issueType || c?.type) === (chatAI.issueType))?.filter((cond) => cond?.name?.toLowerCase() !== 'none') || [] })?.map((d) => {
                                        return (
                                            <ListItemComponent item={d} icon={d?.icon} svgIcon={d?.svgIcon} title={d?.name} onClick={() => updateIssue(d, chatAI)} />
                                        );
                                    })
                                }
                            </>
                        )
                        AppDispatch(updateStepConvo({
                            isYogi: true,
                            text: renderComp,
                            step: chatAI?.step || 1
                        }))
                    } catch (error) {
                        console.log("Error ", error, error.stack)
                    }
                    AppDispatch(setIsLoading(false));
                }
            },
            {
                step: 2,
                isYogi: true,
                text: "Our expert Yogi has put together an amazing blend of {{yogaHelp}}, all tailored to help you conquer {{issueName}}!",
                onLoad: async (chatAI) => {
                    try {
                        if (chatAI.isLoading) {
                            return;
                        }
                        AppDispatch(setIsLoading(true))
                        console.log("chatAI->ONLOADD_+++++++>>>>", chatAI)
                        const yogaHelp = chatAI?.selectedIssue?.yogaHelp || ""
                        const promptText = ` Recommend asana and breathing for a ${chatAI.age} years old ${chatAI.gender} dealing with ${chatAI?.issueName}. Group them by the type (asana and breathing)? Must have result as json containing type(asana and breathing) as key and value as list of asana. Must have englistName, sanskritName,hinglishName and description of each asana.`;
                        const { name, age, gender, issueName } = chatAI || {};

                        const response = await ChatBotAPI.chatGPTHelp({ text: promptText, name, age, gender, issueName, withImage: true, yogaHelp: yogaHelp.split(",")?.map(t => t.trim()).filter(t => t !== 'nutrition'), flowName: 'yoga_weekly_routine' })
                        const { botResponse, imageURL, videoURL, videoThumbnailURL, resultList } = response?.body || {}
                        console.log("videoURL===>", videoURL);

                        console.log("chat typing started", +(new Date()));
                        AppDispatch(updateUserInfo({
                            'yoga_weekly_routine_botResponse': botResponse
                        }))

                        await delayInMs(() => {
                            const tabs = yogaHelp.split(",")?.map(t => t.trim()).filter(t => t !== 'nutrition')
                            let panels = [];
                            tabs.forEach((t) => {
                                panels.push({ type: 'swiper', items: resultList[t] })
                            })
                            AppDispatch(updateConvo({
                                type: 'tab',
                                text: botResponse,
                                isYogi: true,
                                tabs: tabs,
                                panels: panels
                            }));
                            console.log("chat typing stopped", +(new Date()));
                            scrollToBottomConvo();
                        }, MESSAGE_DELAY_MS);
                        moveNextStep()
                    } catch (error) {
                        console.log("EROOOOOORRRR", error, error.stack)
                    }
                    AppDispatch(setIsLoading(false))
                }
            },
            {
                step: 3,
                text: CREATE_SCHEDULE_CONFIRMATION_TEXT,
                type: 'action',
                actions: [{
                    label: 'yes',
                    variant: 'contained',
                    action: () => {
                        sendChatAIMessageText("Yes");
                        moveNextStep(3)
                    },
                    type: 'button'
                },
                {
                    label: 'no',
                    action: () => {
                        sendChatAIMessageText("No");
                        moveNextStep(4)

                    },
                    type: 'button'

                }],
                text: CREATE_SCHEDULE_CONFIRMATION_TEXT,
                isYogi: true,

            },
            {
                step: 4,
                type: 'action',
                text: UNLOCK_FREE_TRIAL,
                isYogi: true,
                layout: 'col',
                blurBg: true,
                condition: (chatAI) => {
                    console.log("condition ===>chatAI", chatAI, chatAI?.email?.length)
                    return !chatAI?.email?.length;
                },
                actions: [{
                    type: 'input',
                    label: 'Email',
                    id: 'weekly-plan-email'
                }, {
                    type: 'button',
                    label: 'Submit',
                    id: 'weekly-plan-email',
                    variant: 'contained',
                    action: (ref, id, cf, chatAI, email) => {
                        const inputText = email || document.getElementById(id)?.value || "";
                        const emailConfirmation = async (email) => {
                            const { name, age, gender, issueName } = chatAI || {};
                            AppDispatch(setIsLoading(true))
                            const response = ChatBotAPI.chatGPTHelp({ text: templatization(CHATGPT_POSE_PROMPT, chatAI), name, age, gender, issueName, createPlan: true, email, sendEmail: true, flowName: 'yoga_weekly_routine' })
                            AppDispatch(setIsLoading(false))
                            AppDispatch(updateConvo({ text: "We have sent you an email, Please check your email for full plan", isYogi: true }))
                            const { yogaHelp } = chatAI?.selectedIssue || "";
                            const yogaHelpList = yogaHelp.split(",").map((t) => t.trim()?.toLowerCase())
                            console.log("yogaHelpList", yogaHelpList, 'nutrition' in yogaHelpList);
                            if (yogaHelpList.includes('nutrition')) {
                                moveNextStep(5)
                            } else {
                                moveNextStep(6)
                            }
                            AppDispatch(setIsLoading(false))
                        }
                        if (inputText?.trim().length > 2) {
                            sendChatAIMessageText(inputText);
                            AppDispatch(updateUserInfo({ email: inputText }))
                            setTimeout(() => {
                                emailConfirmation(inputText);
                            }, 300)
                        }
                    }
                }]

            },
            {
                step: 5,
                text: DONT_CREATE_WEEKLY_SCHEDULE_TEXT,
                type: 'action',
                isYogi: true,
                actions: [{
                    label: 'Yes',
                    type: 'button',
                    variant: 'contained',
                    action: (ref, id, cf, chatAI) => {
                        sendChatAIMessageText("Yes");
                        delayInMs(() => moveNextStep(8), MESSAGE_DELAY_MS)
                    }
                }, {
                    label: 'no',
                    type: 'button',
                    action: () => {
                        sendChatAIMessageText('No')
                        delayInMs(() => moveNextStep(7), MESSAGE_DELAY_MS)
                    }
                }]
            },
            {
                step: 6,
                text: `Unlock the key to managing {{issueName}} with the power of personalized nutrition! 
                We will craft a tailored 7-day nutrition plan just for you. As a bonus, enjoy a 7-day free trial of our guided yoga classes – no credit card required.
Are you ready to take control of your health? Let's create your custom plan together!`,
                type: 'action',
                actions: [{
                    type: 'button',
                    action: async () => {
                        const flowName = 'nutrition'
                        AppDispatch(updateFlow({ flowName: flowName, step: 0, flowData: FLOW_DATA[flowName] }))
                        await registerAndBook(null, false); // create user and update accesstoken on redux
                    },
                    value: "yes",
                    variant: 'contained',
                    label: 'yes'
                }, {
                    type: 'button',
                    action: () => {
                        moveNextStep(6)
                    },
                    value: "no",
                    label: 'no'
                }]
            },
            {
                step: 7,
                type: 'action',
                text: SCHEDULE_CLASS_CONFIRMATION_TEXT,
                actions: [{
                    label: 'yes',
                    variant: 'contained',
                    action: async () => {
                        sendChatAIMessageText("Yes");
                        await registerAndBook()
                        AppDispatch(updateConvo({
                            text: (<>
                                <div className='mb-5'>Is there anything else we can assist you with?</div>
                                <div>
                                    {HOME_FLOW_STEP?.text}
                                </div>
                            </>),
                            isYogi: true
                        }))
                    },
                    type: 'button'
                },
                {
                    label: 'no',
                    action: () => {
                        sendChatAIMessageText("No");
                        moveNextStep(7)
                    },
                    type: 'button'
                }]
            },
            {
                step: 8,
                text: (<>
                    <div className='mb-5'>{DON_WANT_FREE_TRIAL}</div>
                    <div>
                        {HOME_FLOW_STEP?.text}
                    </div>
                </>)

            }, {
                step: 9,
                text: FREE_TRIAL_SCHEDULE,
                type: "action",
                layout: 'col',
                isYogi: true,
                condition: (chatAI) => {
                    return !chatAI?.email?.length;
                },
                actions: [{
                    type: 'input',
                    label: 'Email',
                    id: 'free-schedule-email'
                }, {
                    type: 'button',
                    label: 'Submit',
                    variant: 'contained',
                    id: 'free-schedule-email',
                    action: (ref, id, cf, chatAI, email) => {
                        const inputText = email || document.getElementById(id)?.value || "";
                        console.log("templatization(FREE_TRIAL_SCHEDULE", inputText);
                        if (inputText?.trim().length > 2) {
                            sendChatAIMessageText(inputText);
                            AppDispatch(updateUserInfo({ email: inputText }))
                            setTimeout(() => {
                                registerAndBook(inputText);
                            }, 300)
                        }
                    }
                }]

            }
        ]
    },
    "PRAKRITI_ANALYSIS": {
        steps: [
            {
                step: 1,
                type: 'action',
                text: 'What is your Body frame?',
                tagName: 'bodyFrame',
                layout: 'col',
                actions: [
                    {
                        type: 'button',
                        label: 'Thin, bony, tall or short',
                        value: {
                            type: 'object',
                            value: {
                                'vata': 1,
                                label: 'Thin, bony, tall or short'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: "Medium, Balance",
                        value: {
                            type: 'object',
                            value: {
                                'pitta': 1,
                                label: 'Medium, Balance'
                            }
                        }
                    }, {
                        type: 'button',
                        label: "Large, broad",
                        value: {
                            type: 'object',
                            value: {
                                'kapha': 1,
                                'label': 'Large, broad'
                            }
                        }
                    },
                ]
            },
            {
                step: 2,
                type: 'action',
                text: 'What is your body Weight',
                tagName: 'bodyWeight',
                layout: 'col',
                actions: [
                    {
                        type: 'button',
                        label: 'Low',
                        value: {
                            type: 'object',
                            value: {
                                'vata': 1,
                                label: 'Low'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Moderate',
                        value: {
                            type: 'object',
                            value: {
                                'pitta': 1,
                                label: 'Moderate'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Heavy',
                        value: {
                            type: 'object',
                            value: {
                                'kapha': 1,
                                label: 'heavy'
                            }
                        }
                    }

                ]
            },
            {
                step: 3,
                type: 'action',
                text: 'Could you please tell us about your body Skin',
                tagName: 'skin',
                layout: 'col',
                actions: [
                    {
                        type: 'button',
                        label: 'Dry, rough, Cool, dull',
                        value: {
                            type: 'object',
                            value: {
                                'vata': 1,
                                label: 'Dry, rough, Cool, dull'
                            }
                        }
                    },

                    {
                        type: 'button',
                        label: 'Soft, oily, Warm, Reddish',
                        value: {
                            type: 'object',
                            value: {
                                'pitta': 1,
                                label: 'Soft, oily, Warm, Reddish'
                            }
                        }
                    },

                    {
                        type: 'button',
                        label: 'Thick, moist, cold, pale, fair',
                        value: {
                            type: 'object',
                            value: {
                                'kapha': 1,
                                label: 'Thick, moist, cold, pale, fair'
                            }
                        }
                    },
                ]
            },
            {
                step: 4,
                type: 'action',
                text: 'Could you please tell me about the condition of your hairs?',
                tagName: 'hair',
                layout: 'col',
                actions: [
                    {
                        type: 'button',
                        label: 'Scanty, Dark, dry, curly',
                        value: {
                            type: 'object',
                            value: {
                                'vata': 1,
                                label: 'Scanty, Dark, dry, curly '
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Soft, oily, Fair/ red, bald',
                        value: {
                            type: 'object',
                            value: {
                                'pitta': 1,
                                label: 'Soft, oily, Fair/ red, bald '
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Abundant, Thick, oily, Wavy',
                        value: {
                            type: 'object',
                            value: {
                                'kapha': 1,
                                label: 'Abundant, Thick, oily, Wavy'
                            }
                        }
                    },
                ],

            },
            {
                step: 5,
                type: 'action',
                text: 'Could you please tell me about the condition of your Teeth & Gums?',
                tagName: 'teethGum',
                layout: 'col',
                actions: [
                    {
                        type: 'button',
                        label: 'Protruding, big, uneven, receding',
                        value: {
                            type: 'object',
                            value: {
                                'vata': 1,
                                label: 'Protruding, big, uneven, receding'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Moderate, yellowish, pink gums, bleeding',
                        value: {
                            type: 'object',
                            value: {
                                'pitta': 1,
                                label: 'Moderate, yellowish, pink gums, bleeding'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Strong, even, white, pink and oily gums',
                        value: {
                            type: 'object',
                            value: {
                                'kapha': 1,
                                label: 'Strong, even, white, pink and oily gums'
                            }
                        }
                    },
                ],
            },
            {
                step: 6,
                type: 'action',
                text: 'Could you please tell me about the condition of your Joints?',
                tagName: 'joints',
                layout: 'col',
                actions: [
                    {
                        type: 'button',
                        label: 'Bow / locked knee, cracking, unsteady',
                        value: {
                            type: 'object',
                            value: {
                                'vata': 1,
                                label: 'Bow / locked knee, cracking, unsteady'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Medium, soft, loose',
                        value: {
                            type: 'object',
                            value: {
                                'pitta': 1,
                                label: 'Medium, soft, loose'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Thick, large, well built',
                        value: {
                            type: 'object',
                            value: {
                                'kapha': 1,
                                label: 'Thick, large, well built'
                            }
                        }
                    },
                ],
            },
            {
                step: 7,
                type: 'action',
                text: 'Could you please tell me about the condition of your Appetite?',
                tagName: 'appetite',
                layout: 'col',
                actions: [
                    {
                        type: 'button',
                        label: 'Variable, small, erratic, snacks',
                        value: {
                            type: 'object',
                            value: {
                                'vata': 1,
                                label: 'Variable, small, erratic, snacks'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Strong, regular, sharp, Requires regular meals',
                        value: {
                            type: 'object',
                            value: {
                                'pitta': 1,
                                label: 'Strong, regular, sharp, Requires regular meals'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Constant, Fatty, Gourmet, luxury',
                        value: {
                            type: 'object',
                            value: {
                                'kapha': 1,
                                label: 'Constant, Fatty, Gourmet, luxury'
                            }
                        }
                    },
                ],
            },
            {
                step: 8,
                type: 'action',
                text: 'Could you please tell me about your bowel movements?',
                tagName: 'bowel',
                layout: 'col',
                actions: [
                    {
                        type: 'button',
                        label: 'Scanty, Dry, hard, constipated, gas, painful',
                        value: {
                            type: 'object',
                            value: {
                                'vata': 1,
                                label: 'Scanty, Dry, hard, constipated, gas,painful'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Abundant, Soft, oily, loose, burning sensation',
                        value: {
                            type: 'object',
                            value: {
                                'pitta': 1,
                                label: 'Abundant, Soft, oily, loose, burning sensation'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Thick, solid, slow',
                        value: {
                            type: 'object',
                            value: {
                                'kapha': 1,
                                label: 'Thick, solid, slow'
                            }
                        }
                    },
                ],
            },
            {
                step: 9,
                type: 'action',
                text: 'Could you please tell me about urine?',
                tagName: 'urine',
                layout: 'col',
                actions: [
                    {
                        type: 'button',
                        label: 'Frequent scanty, colorless, painful',
                        value: {
                            type: 'object',
                            value: {
                                'vata': 1,
                                label: 'Frequent scanty, colorless, painful'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Profuse, Yellow, warm, burning',
                        value: {
                            type: 'object',
                            value: {
                                'pitta': 1,
                                label: 'Profuse, Yellow, warm, burning'
                            }
                        }
                    }, {
                        type: 'button',
                        label: 'Infrequent, average, milky',
                        value: {
                            type: 'object',
                            value: {
                                'kapha': 1,
                                label: 'Infrequent, average, milky'
                            }
                        }
                    },
                ],
            },
            {
                step: 10,
                type: 'action',
                text: 'Could you please tell me about your sleep?',
                tagName: 'sleep',
                layout: 'col',
                actions: [
                    {
                        type: 'button',
                        label: 'Light, disturbed, minimal',
                        value: {
                            type: 'object',
                            value: {
                                'vata': 1,
                                label: 'Light, disturbed, minimal'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Sound, moderate',
                        value: {
                            type: 'object',
                            value: {
                                'pitta': 1,
                                label: 'Sound, moderate'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Heavy, excessive',
                        value: {
                            type: 'object',
                            value: {
                                'kapha': 1,
                                label: 'Heavy, excessive'
                            }
                        }
                    },
                ],
            },
            {
                step: 11,
                type: 'action',
                text: 'Could you please tell me about your speech/',
                tagName: 'speech',
                layout: 'col',
                actions: [
                    {
                        type: 'button',
                        label: 'Quick, inconsistent, talkative',
                        value: {
                            type: 'object',
                            value: {
                                'vata': 1,
                                label: 'Quick, inconsistent, talkative'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Sharp, argumentative, convincing',
                        value: {
                            type: 'object',
                            value: {
                                'pitta': 1,
                                label: 'Sharp, argumentative, convincing'
                            }
                        }
                    },
                    {
                        type: 'button',
                        label: 'Slow, sweet, steady',
                        value: {
                            type: 'object',
                            value: {
                                'kapha': 1,
                                label: 'Slow, sweet, steady'
                            }
                        }
                    },
                ],
            },
            {
                step: 11,
                type: 'action',
                text: 'Could you please tell me about your mind?',
                tagName: 'mind',
                layout: 'col',
                actions: [
                    {
                        type: 'button',
                        label: 'Restless, Curious, original ideas,adaptive',
                        value: {
                            type: 'object',
                            value: {
                                'vata': 1,
                                label: 'Restless, Curious, original ideas,adaptive'
                            }
                        },
                        noNext: true,
                    },
                    {
                        type: 'button',
                        label: 'Aggressive, clever, efficient, critical,practical',
                        value: {
                            type: 'object',
                            value: {
                                'pitta': 1,
                                label: 'Aggressive, clever, efficient, critical,practical'
                            }
                        },
                        noNext: true,
                    },
                    {
                        type: 'button',
                        label: 'Calm, slow, dull, thinks from the heart',
                        value: {
                            type: 'object',
                            value: {
                                'kapha': 1,
                                label: 'Calm, slow, dull, thinks from the heart'
                            }
                        },
                        noNext: true,
                    },
                ],

                onSelect: async (data) => {
                    console.log("Custom func", data);
                    const result = customFunc(data)
                    AppDispatch(setTyping(true));
                    await delayInMs(() => {
                        let _result = [{ key: "vata", val: result?.vata || -999 }, { key: "pitta", val: result?.pitta || -999 }, { key: "kapha", val: result?.kapha || -999 }];
                        console.log("===>>>>_result:", _result, [result[0], result[1]]);
                        _result.sort((a, b) => a.val > b.val ? -1 : 1)
                        const same = _result[0].val === _result[1].val;
                        let values = ""
                        if (same) {
                            values =
                                (
                                    <>
                                        {`are ${_result[0].key} and ${_result[1].key}`}
                                        <div className='flex gap-2'>
                                            {[_result[0], _result[1]]?.map((i) => {
                                                let _src = ayurvedicIcon(i.key);

                                                return <img src={_src} />
                                            })}
                                        </div>
                                    </>
                                );
                        } else {
                            let _src = ayurvedicIcon(_result[0].key)
                            values =
                                (<>
                                    <div>{`is ${_result[0].key}`}</div>
                                    <img src={_src} />
                                </>);
                        }
                        const renderComp = (
                            <>
                                <div>Your ayurvedic constitution {values}</div>
                            </>
                        )
                        AppDispatch(updateConvo({
                            text: renderComp,
                            isYogi: true
                        }))
                        console.log("chat typing stopped", +(new Date()));
                        scrollToBottomConvo();
                        AppDispatch(setTyping(false));
                        const { source } = data || {};
                        if (source !== 'home') {
                            const flowName = 'nutrition'
                            AppDispatch(updateFlow({ flowName: flowName, step: 1, flowData: FLOW_DATA[flowName] }))
                        }

                    }, MESSAGE_DELAY_MS);

                }
            },
        ]
    }
}


const customFunc = (data) => {
    const { values } = data || {};
    let result = {};
    for (let i of Object.keys(values)) {
        const v = values[i]
        console.log("CF v===>", i, v)
        v.forEach((val) => {
            console.log("CF i", i, val)
            if (typeof (val) !== 'object') {
                return;
            }
            console.log("CF ii", i, Object.keys(val?.value || {}))
            const k = Object.keys(val.value).filter(k => ['vata', 'pitta', 'kapha'].includes(k.toLowerCase()))[0]
            const _v = val.value[k];
            console.log("CF iii", k, _v)
            if (result[k]) {
                result[k] += _v;
            } else {
                result[k] = _v;
            }
        })
    }
    return result;
}