/**
 *
 * PopverComponent
 *
 */

import { IconButton, Popover } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { Children, memo } from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

export function PopoverComponent({ children, buttonComponent }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return <>
    <IconButton onClick={handleClick}>
      {buttonComponent ? buttonComponent({ onClick: handleClick }) : <MoreVert />}
    </IconButton>
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      {children}
    </Popover>
  </>;
}

PopoverComponent.propTypes = {};


