import { configureStore } from '@reduxjs/toolkit'
import shvasaChatAIReducer from './ShvasaChatAiPage/shvasaChatAISlice';


import { connectRouter } from 'connected-react-router'
export const reduxStore = configureStore({
    reducer: {
        chatAI: shvasaChatAIReducer
    }
})

export default reduxStore;
export const AppDispatch = reduxStore.dispatch;