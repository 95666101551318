import React from 'react';
import { Box, Tabs, Tab, Button } from '@mui/material'
import { ActionCard, SwiperItemList } from '../ShvasaChatAIConvo';

export const TabPane = ({ tabs, panels }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: '100%' }}>
            <Box className='flex gap-2 justify-center items-center'>
                {
                    tabs.filter((t) => t?.trim().length).map((tab, index) => {
                        const isActive = value === index;
                        return (<Button style={{ backgroundColor: isActive ? '#1A76F8' : '', position: 'relative', fontSize: 10, fontWeight: 500, textTransform: 'capitalize' }} onClick={() => setValue(index)} variant={isActive ? 'contained' : 'outlined'} className={isActive ? 'tooltip-down-arrow' : ''} disableElevation size='small' >{tab}</Button>)
                    })
                }

            </Box>
            <Box className='py-8'>
                {
                    panels?.map((pane, index) => {
                        return (<Box className={`${value === index ? '' : 'hidden'}`}>
                            {pane?.type === 'swiper' ? <SwiperItemList {...pane} /> : ''}
                            {pane?.type === 'action' ? <ActionCard {...pane} /> : ''}
                            {pane?.type === 'text' || !pane?.type ? pane : ''}
                        </Box>)
                    })
                }
            </Box>
        </Box>
    );
}