import React from 'react';
import { ArthritisIcon, DiabetesIcon, HyperTensionIcon, KneeIssueIcon, NoneHeartIcon, OtherIcon, PCOSIcon, ShouldNeckPainIcon, ThyroidIcon, BackPainIcon, Wellness4, Wellness5, Wellness8 } from '../ShvasaChatAiPage/svgIcons';
export const medicalIssueIconsListMap = {
    "arthritis": (<ArthritisIcon />),
    "diabetes": (<DiabetesIcon />),
    "none": (<NoneHeartIcon />),
    "shoulder": (<ShouldNeckPainIcon />),
    "knee": (<KneeIssueIcon />),
    "pcos": (<PCOSIcon />),
    "thyroid": (<ThyroidIcon />),
    "hypertension": (<HyperTensionIcon />),
    "back": (<BackPainIcon />),
    "other": (<OtherIcon />),
    "stress": <Wellness4 />,
    "anxiety": <Wellness5 />,
    "sleeplessness": <Wellness8 />,
}


export const medicalIssueWithIcons = ({ conditionsList }) => {
    console.log("conditionsList==>", conditionsList,);
    return conditionsList.map(cond => {
        console.log("cond====>", cond);
        const iconText = cond?.name?.toLowerCase().split(" ")[0];
        return { ...cond, svgIcon: medicalIssueIconsListMap[iconText] }
    });
}

