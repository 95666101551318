import axios from 'axios';


/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */

function parseJSON(response) {
  console.log("parseJSON", response.status);
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.data;
}



/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  console.log('parse json', response);
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(path, options) {
  console.log("ENV VAR", process.env);
  const requestUrl = (path.indexOf('http://') > -1 || path.indexOf('https://') > -1) ? path : process.env.REACT_APP_API_URL + path;
  if (!options) options = {};
  options.url = requestUrl;
  let headers = options.headers || {};
  headers['Content-Type'] = 'application/json';

  options.headers = headers;
  console.log('axios .....request', path, ' = = ', options);
  return axios(options)
    .then(checkStatus)
    .then(parseJSON);
}
