import { Box, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

import './style.css'



export const MultiSelectOption = (props) => {
    const [state, setData] = useState({ selected: [] })
    const setState = (data) => {
        setData((prev) => ({ ...prev, ...data }))
    }
    const selectOption = (opt) => {
        const { selected } = state;
        const ind = selected.indexOf(opt);
        console.log(opt, 'option index', ind, selected);


        if (ind > -1) {
            selected.splice(ind, 1)
            setState({ selected })
        } else {
            selected.push(opt)
            setState({ selected })
        }
        //props.onChoose && props.onChoose(state, opt);
    }

    useEffect(() => {
        console.log("setSelected updated", state.selected, props.onChoose)
        props.onChoose && props.onChoose(state.selected);
    }, [state])



    return (
        <Box className={`flex goals-list flex-wrap justify-center gap-2`}>
            {
                props?.options?.map(option => {
                    const isActive = state.selected.indexOf(option) < 0;
                    return (
                        <Box className={` flex flex-col text-center`} key={option.text}>
                            <Box style={{ maxWidth: props.itemMaxWidth ? props.itemMaxWidth : 90 }} >
                                <Button fullWidth={true} onClick={() => selectOption(option)} className="flex rounded-full border-0">
                                    <Box className="flex flex-col">
                                        <Box className={`relative ${state.selected.indexOf(option) > -1 ? 'active-icon' : ''} `}>
                                            {
                                                option.img
                                                    ? (
                                                        <img src={option.img} />
                                                    )
                                                    : ''
                                            }
                                            {
                                                option.svgIcon
                                                    ? (
                                                        option.svgIcon
                                                    )
                                                    : ''
                                            }
                                        </Box>
                                        <Box style={{ color: isActive ? '#4D4D4D' : '#fc8f00' }} className={`font-light capitalize  `}>{option.text}</Box>
                                    </Box>
                                </Button>
                            </Box>
                        </Box>
                    )
                })
            }
        </Box>
    )
}