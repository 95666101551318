import { SVGIcon1, SVGIcon2, SVGIcon3, SVGIcon4, SVGIcon5, SVGIcon6, SVGIcon7, SVGIcon8, SVGIcon9, SVGIcon10, SVGIcon11, SVGIcon12, SVGIcon13, SVGIcon14, SVGIcon15 } from "./goals-svg-icons";

export const YogaGoalMapping =
{
    "Weight-loss": {
        "active": true,
        "_id": "64edeabb6fbca248c95624e6",
        "name": "Weight-loss",
        "svgIcon": <SVGIcon1 />
    }
    ,

    "Hair growth": {
        "active": true,
        "_id": "64edeabb6fbca248c95624e7",
        "name": "Hair growth",
        "svgIcon": <SVGIcon2 />
    }
    ,
    "Athletes": {
        "active": true,
        "_id": "64edeabb6fbca248c95624e8",
        "name": "Athletes",
        "svgIcon": <SVGIcon3 />
    }
    ,
    "Diabetes": {
        "active": true,
        "_id": "64edeabb6fbca248c95624e9",
        "name": "Diabetes",
        "svgIcon": <SVGIcon4 />
    }
    ,
    "Strong bones": {
        "active": true,
        "_id": "64edeabb6fbca248c95624ea",
        "name": "Strong bones",
        "svgIcon": <SVGIcon5 />
    }
    ,
    "Thyroid": {
        "active": true,
        "_id": "64edeabb6fbca248c95624eb",
        "name": "Thyroid",
        "svgIcon": <SVGIcon6 />
    }
    ,
    "Weight gain": {
        "active": true,
        "_id": "64edeabb6fbca248c95624ec",
        "name": "Weight gain",
        "svgIcon": <SVGIcon7 />
    }
    ,
    "Heart health": {
        "active": true,
        "_id": "64edeabb6fbca248c95624ed",
        "name": "Heart health",
        "svgIcon": <SVGIcon8 />
    }
    ,
    "Runners": {
        "active": true,
        "_id": "64edeabb6fbca248c95624ee",
        "name": "Runners",
        "svgIcon": <SVGIcon9 />
    }
    ,
    "Eyesight": {
        "active": true,
        "_id": "64edeabb6fbca248c95624ef",
        "name": "Eyesight",
        "svgIcon": <SVGIcon10 />
    }
    ,

    "Muscle Gain": {
        "active": true,
        "_id": "64edeabb6fbca248c95624f0",
        "name": "Muscle Gain",
        "svgIcon": <SVGIcon11 />
    }
    ,

    "Other": {
        "active": true,
        "_id": "64edeabb6fbca248c95624f1",
        "name": "Other",
        "svgIcon": <SVGIcon12 />
    }
}
