import React from 'react';
import { ArrowForwardBlueSVGIcon, AyurvedicDoshaSVGIcon, NutritionPlanSVGIcon, PhysicalHealthIssueSVGIcon, WaveSVGIcon } from './svg-icons';
import { updateFlow, updateMedicalIssueInfo } from './shvasaChatAISlice';
import { AppDispatch } from '../configureStore';
import { NavLink, Redirect, useNavigate } from 'react-router-dom';
import { CHAT_AI_STORAGE_KEY, CHOOSE_TYPE_OF_ISSUE_HEAD, CHOOSE_TYPE_OF_ISSUE_TEXT, YOGA_AI_INTRO } from './constants';
import { Button, Slide } from '@mui/material';
import { FLOW_DATA } from './flow_data';


const selectCurrentFlowData = (flowName, issueType = null) => {
    AppDispatch(updateMedicalIssueInfo({ 'issueType': issueType }))
    console.log("FLOW_DATA[flowName]:", FLOW_DATA[flowName], { flowName, issueType }, issueType ? null : 'home');
    AppDispatch(updateFlow({ flowName: flowName, step: 0, flowData: FLOW_DATA[flowName], source: issueType === null ? null : 'home' }))

}

const bookConsultation = () => {
    window.open('https://app.shvasa.com/book-consultation/', '_target')
}

export const HOME_FLOW_STEP = {
    text: (
        <div className='w-full '>
            <div className='bg-white rounded-2xl pl-2 pr-3 shadow-sm'>
                <div onClick={() => selectCurrentFlowData('mentalmedicalIssue', 'mental')} className='flex justify-between items-center'>
                    <div className='flex items-center'>
                        <div><PhysicalHealthIssueSVGIcon /></div>
                        <div>Mental health </div>
                    </div>
                    <div><ArrowForwardBlueSVGIcon /></div>
                </div>
            </div>
            <div className='bg-white rounded-2xl pl-2 pr-3 shadow-sm my-4'>
                <div onClick={() => selectCurrentFlowData('physicalmedicalIssue', 'physical')} className='flex justify-between items-center'>
                    <div className='flex items-center'>
                        <div><PhysicalHealthIssueSVGIcon /></div>
                        <div className='ml-2'>Physical health </div>
                    </div>
                    <div><ArrowForwardBlueSVGIcon /></div>
                </div>
            </div>
            <div className='bg-white rounded-2xl pl-2 pr-3 shadow-sm my-4'>
                <div onClick={() => {
                    selectCurrentFlowData('nutrition')
                }
                } className='flex justify-between items-center'>
                    <div className='flex items-center'>
                        <div>
                            <NutritionPlanSVGIcon />
                        </div>
                        <div className='ml-2'>Nutrition Plan</div>
                    </div>
                    <div><ArrowForwardBlueSVGIcon /></div>
                </div>
            </div>
            <div className='bg-white rounded-2xl pl-2 pr-3 shadow-sm my-4'>
                <div onClick={() => selectCurrentFlowData('PRAKRITI_ANALYSIS')} className='flex justify-between items-center'>
                    <div className='flex items-center'><div>
                        <AyurvedicDoshaSVGIcon />
                    </div>
                        <div className='ml-2'>Ayurvedic Dosha Test</div></div>
                    <div><ArrowForwardBlueSVGIcon /></div>
                </div>
            </div>
            <div className='bg-white rounded-2xl pl-2 pr-3 shadow-sm my-4'>
                <div onClick={() => bookConsultation()} className='flex justify-between items-center'>
                    <div className='flex items-center'><div>
                        <AyurvedicDoshaSVGIcon />
                    </div>
                        <div className='ml-2'>Book Consultation</div></div>
                    <div><ArrowForwardBlueSVGIcon /></div>
                </div>
            </div>
        </div>
    ),
    isYogi: true,
} 